import React, { useContext } from 'react'

import BetContext from './context/bet'

const OptionalAdditionalInfo = ({ bet }) => {
  bet || (bet = useContext(BetContext))

  return bet.extra_info
    ? <div className="cancelledInfo__wrapper">
        {bet.extra_info}
      </div>
    : null
}

export default OptionalAdditionalInfo
