import React, { useContext } from 'react'

import BetContext from '@root/bet_utils/context/bet'
import { TeamContext } from './context'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import { EditablePick, FixedPick } from './Pick'

const Team = (_) => {
  const bet = useContext(BetContext)
  const team = useContext(TeamContext)

  return (
    <div className='scoreBet__team'>
      <div className='scoreBet__team__teamSection'>
        <img className='scoreBet__team__image' src={team.image} />
        <h4 className='scoreBet__team__name'>{team.name}</h4>
      </div>

      <div className='scoreBet__team__pickSection'>
        <div className='scoreBet__team__pickPositioner'>
          {betStatusPresenter.canEdit(bet) &&
            <EditablePick />}
          {!betStatusPresenter.canEdit(bet) &&
            <FixedPick />}
        </div>
      </div>
    </div>
  )
}

export default Team
