import { getIframePlacement, whenIframePlacementProperlyFilled } from '../redbull/custom_script_integration'

const modalSaveZone = {
  calculate: () => {
    let placement
    if (window.parent === window) {
      // standalone
      placement = {
        leftOffset: 0,
        parentWidth: Math.floor(document.body.clientWidth),
        topOffset: 0, // -Math.floor(window.pageYOffset),
        parentHeight: Math.floor(window.innerHeight)
      }
    } else {
      placement = {
        leftOffset: 0,
        parentWidth: Math.floor(window.innerWidth),
        ...getIframePlacement()
      }
      // hack beacuse redbull page has sticky overlaying header
      placement.parentHeight -= 85
      placement.topOffset -= 85
    }
    const result = {
      centerX: Math.floor(-placement.leftOffset + (placement.parentWidth / 2)),
      centerY: Math.floor(-placement.topOffset + (placement.parentHeight / 2)),
      width: Math.floor(placement.parentWidth * 0.95),
      height: Math.floor(placement.parentHeight * 0.75)
    }
    // Keep in visible area!
    if (result.centerY < result.height * 0.3) {
      result.centerY = Math.floor(result.height * 0.3)
    } else if (result.centerY > document.body.clientHeight - (result.height * 0.3)) {
      result.centerY = Math.floor(document.body.clientHeight - (result.height * 0.3))
    }
    return result
  },
  onReady: (callback) => {
    if (window.parent === window) {
      if (__properlyFilled) {
        callback()
      } else {
        __whenFilledOnStandalone.push(callback)
      }
    } else {
      whenIframePlacementProperlyFilled(callback)
    }
  }
}

const __whenFilledOnStandalone = []
let __properlyFilled = false
const onStandaloneWindowLoad = () => {
  __properlyFilled = true
  while (__whenFilledOnStandalone.length > 0) {
    __whenFilledOnStandalone.pop()()
  }
  window.removeEventListener('load', onStandaloneWindowLoad)
}
window.addEventListener('load', onStandaloneWindowLoad)

export default modalSaveZone
