/* global describe, it */
import { assert } from 'chai'

import { pickPresenter } from './presenter'

describe('ScoreBet: pickPresenter', () => {
  it('not picked when -1', () => {
    assert.isFalse(pickPresenter.isPicked(-1))
  })

  it('picked when not -1', () => {
    assert.isTrue(pickPresenter.isPicked(0))
    assert.isTrue(pickPresenter.isPicked(12))
  })

  it('Increasing "not picked" to "0"', () => {
    assert.isTrue(pickPresenter.canIncrease(-1))
    assert.strictEqual(pickPresenter.increased(-1), 0)
  })

  it('Can\'t decrease "not picked"', () => {
    assert.isFalse(pickPresenter.canDecrease(-1))
    assert.strictEqual(pickPresenter.decreased(-1), -1)
  })

  it('Don\' decrease below "0"', () => {
    assert.isFalse(pickPresenter.canDecrease(0))
    assert.strictEqual(pickPresenter.decreased(0), 0)
  })

  it('Don\' increase above "20"', () => {
    assert.isFalse(pickPresenter.canIncrease(20))
    assert.strictEqual(pickPresenter.increased(20), 20)
  })

  it('Show pick as "?" when censored', () => {
    const bet = { censored: true }
    assert.strictEqual(pickPresenter.pretty(bet, 1), '?')
  })

  it('hasPickAtIndex and getPickAtIndex', () => {
    const fallbackIfNone = -1

    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [] }, 1), false)
    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [-1] }, 0), false)
    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [1] }, 0), true)
    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [1] }, 1), false)
    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [1, 2] }, 1), true)
    assert.strictEqual(pickPresenter.hasPickAtIndex({ picks: [1, -1] }, 1), false)

    assert.strictEqual(pickPresenter.getPickAtIndex({ picks: [] }, 1), fallbackIfNone)
    assert.strictEqual(pickPresenter.getPickAtIndex({ picks: [1] }, 0), 1)
    assert.strictEqual(pickPresenter.getPickAtIndex({ picks: [1] }, 1), fallbackIfNone)
    assert.strictEqual(pickPresenter.getPickAtIndex({ picks: [1, 2] }, 1), 2)
  })

  it('Show unset pick as "-" when censored', () => {
    const bet = { }
    assert.strictEqual(pickPresenter.pretty(bet, -1), '-')
  })

  it('Show pick as itself when nothing special', () => {
    const bet = { }
    assert.strictEqual(pickPresenter.pretty(bet, 20), '20')
  })
})
