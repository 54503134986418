import React, {useRef} from "react"
import I18n from "i18n-js"

import Button from "./Button"

const FormRow = ({label, children, second, subLabel}) => {
  const secondClass = (second) ? " changeCommunitySettings__row--second" : ""
  return (
    <div className={"changeCommunitySettings__row" + secondClass}>
      <div className="changeCommunitySettings__labelContainer">
        <div className="changeCommunitySettings__label">{label}</div>
        {subLabel && <div className="changeCommunitySettings__subLabel">{subLabel}</div>}
      </div>
      <div className="changeCommunitySettings__input">
        {children}
      </div>
    </div>
  )
}

const PrivacyRadio = ({id, label, subLabel, iconClass, value, checked}) => {
  return (
    <div className="radio changeCommunitySettingsTable__radio pointerForAllChildren">
      <input id={id} className="radio__input" type="radio" value={value} defaultChecked={checked} name="community[private]"/>
      <label className="radio__label" htmlFor={id}>
        <div className={`radio__title radio__title--icon icon ${iconClass}`}>
          {label}
        </div>
        <div className="radio__title radio__title--sub">
          {subLabel}
        </div>
      </label>
    </div>
  )
}

const Form = ({
  community_action: communityAction,
  authenticity_token: authenticityToken,
  community_name: communityName,
  community_description: communityDescription,
  community_private: communityPrivate,
  method,
  flash,
}) => {
  const formRef = useRef()
  const nameClass = "input" + ((flash.community_error) ? " input--error" : "")
  return (
    <div>
      <form ref={formRef} className="changeCommunitySettings__main" action={communityAction} acceptCharset="utf-8" method="post">
        <input type="hidden" name="_method" value={method} autoComplete="off" />
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        <FormRow label={I18n.t("community_settings.label.communtiy_name")}>
          <input className={nameClass} type="text" defaultValue={communityName} name="community[title]" id="community_title"/>
          {flash.community_error && <span className="form__error">{flash.community_error}</span>}
        </FormRow>
        <FormRow label={I18n.t("community_settings.label.community_description")} subLabel={I18n.t("community_settings.label.explanation")}>
          <textarea className="input" style={{height: "unset"}} rows={10} name="community[description]" id="community_description" defaultValue={communityDescription} />
        </FormRow>
        <FormRow second label={I18n.t("community_settings.label.privacy")}>
          <PrivacyRadio
            id="community_public"
            label={I18n.t("community_settings.public.title")}
            subLabel={I18n.t("community_settings.public.description")}
            iconClass="unlocked"
            value={false}
            checked={!communityPrivate}
          />
          <PrivacyRadio
            id="community_private"
            label={I18n.t("community_settings.private.title")}
            subLabel={I18n.t("community_settings.private.description")}
            iconClass="locked"
            value={true}
            checked={communityPrivate}
          />
        </FormRow>
        <FormRow>
          <Button className="changeCommunitySettings__button" secondary onClick={() => formRef.current?.submit()}>{I18n.t("community_settings.save")}</Button>
        </FormRow>
      </form>
    </div>
  )
}

export default Form