import moment from 'moment'
import _ from "lodash"

const isValidResult = (res) => {
  return res && res.length > 0
}

const betPresenter = {
  isMatchOverAndScored: (bet) => {
    return bet.event_state === 'over' && !!bet.final_results && isValidResult(bet.final_results)
  },
  isMatchOver: (bet) => {
    return bet.match_over || bet.event_over || bet.race_over
  },
  isMatchOverAndWaitingForScores: (bet) => {
    return bet.event_state === 'progress' && betPresenter.isMatchOver(bet)
  },
  isMatchInProgress: (bet) => {
    return (bet.event_state === 'progress' && !betPresenter.isMatchOver(bet)) ||
      (bet.event_state === 'open' && bet.secondsLeft <= 0)
  },
  questionWaitingForScoring: (bet) => {
    return (betPresenter.isMatchOver(bet) || betPresenter.isMatchInProgress(bet))
      && !isValidResult(bet.final_results?.[0])
      && !bet.cancelled
      && !bet.postponed
  },
  isMatchOpen: (bet) => {
    return bet.event_state === 'open' && bet.secondsLeft !== 0
  },
  canEdit: (bet) => {
    return betPresenter.isMatchOpen(bet) && !bet.from_other
  },
  isFromOther: (bet) => {
    return !!bet.from_other
  },
  isCensored: (bet) => {
    return !!bet.censored
  },
  prettyDate: (bet) => {
    return moment(bet.event_date).format('D MMM | HH:mm')
  },
  isScoringFinal: (bet) => {
    return betPresenter.isMatchOverAndScored(bet) && !bet.temp_scored
  },
  receivedPoints: (bet) => {
    return betPresenter.isMatchOverAndScored(bet) && bet.total_score && (bet.total_score > 0)
  }
}

export default betPresenter
