import React from 'react'
// import Clipboard from 'react-clipboard.js'
import ClipboardJS from '@vendor/clipboard.min'

class CopyToClipboard extends React.Component {
  constructor (props) {
    super(props)

    this.copyButtonRef = null
    this.clipboardInstance = null

    this.state = {
      justCopied: false,
      justCopiedTimeout: null
    }
    this.justCopied = this.justCopied.bind(this)
    this.afterJustCopiedTimeout = this.afterJustCopiedTimeout.bind(this)
    this.setCopyButtonRef = this.setCopyButtonRef.bind(this)
    this.getTextForClipboard = this.getTextForClipboard.bind(this)
  }

  getTextForClipboard () { return this.props.text }

  setCopyButtonRef (el) {
    if (this.copyButtonRef !== el) {
      if (this.clipboardInstance) {
        this.clipboardInstance.destroy()
        this.clipboardInstance = null
      }
      this.copyButtonRef = el

      if (el) {
        this.clipboardInstance = new ClipboardJS(el, { text: this.getTextForClipboard })
        this.clipboardInstance.on('success', this.justCopied)
      }
    }
  }

  afterJustCopiedTimeout () {
    this.setState({
      justCopied: false,
      justCopiedTimeout: null
    })
  }

  justCopied () {
    if (this.state.justCopiedTimeout) {
      clearTimeout(this.state.justCopiedTimeout)
    }
    const timeout = setTimeout(this.afterJustCopiedTimeout, 1200)

    this.setState({
      justCopied: true,
      justCopiedTimeout: timeout
    })
  }

  componentWillUnmount () {
    if (this.state.justCopiedTimeout) {
      clearTimeout(this.state.justCopiedTimeout)
    }
  }

  selectAllText (event) {
    event.target.select()
  }

  render () {
    const {
      text,
      onClose
    } = this.props

    return (
      <div className={'copyToClipboard'}>
        <input readOnly type='text' value={text} onFocus={this.selectAllText} />
        <button ref={this.setCopyButtonRef} className={'copyToClipboard__copyButton' + (this.state.justCopied ? ' --justCopied' : '')}>
          Copy!
        </button>
        {onClose &&
          <span className='icon icon--close--rb-gray' onClick={onClose} />
        }
      </div>
    )
  }
}

export default CopyToClipboard
