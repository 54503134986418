const NEO_NAMESPACE = "__Neo"

const exportGlobally = (namesp: string, obj: any) => {
  if (!(window as any)[NEO_NAMESPACE]) { (window as any)[NEO_NAMESPACE] = {} }
  const __Neo = (window as any)[NEO_NAMESPACE] as {[key: string]: any}
  if (__Neo[namesp]) { throw new Error(`exportGlobally namespace '${namesp}' already exists`)}
  __Neo[namesp] = obj
}

export default exportGlobally
