import _ from "lodash"

import betStatusPreseter from "@root/bet_utils/presenter/bet_status"

export const textPickPresenter = {
  isBetPicked: (bet: TextSelection.Api.Bet) => { return !!(bet.picks && bet.picks.length > 0 && textPickPresenter.isValidPick(bet.picks?.[0] || 0)) },
  pickedId: (bet: TextSelection.Api.Bet) => { return (textPickPresenter.isBetPicked(bet) && bet.picks?.[0]) || null },
  isValidPick: (pickId: any) => { return _.isString(pickId) && pickId.length !== 0 },
  answers: (bet: TextSelection.Api.Bet) => { return bet.answers || [] },
  pickedAnswer: (bet: TextSelection.Api.Bet) => {
    if (!textPickPresenter.isBetPicked(bet)) { return null }
    const pickedId = textPickPresenter.pickedId(bet)
    return _.find(textPickPresenter.answers(bet), (answer) => { return answer.id === pickedId }) || null
  },
  prettyPick: (bet: TextSelection.Api.Bet) => {
    if (textPickPresenter.isBetPicked(bet)) {
      return textPickPresenter.pickedAnswer(bet)?.name || ""
    }
    if (betStatusPreseter.isCensored(bet)) { return "?" }
    return "-"
  },
  correctAnswer: (bet: TextSelection.Api.Bet) => {
    if (!betStatusPreseter.isMatchOverAndScored(bet)) { return null }
    if (!textPickPresenter.isValidPick((bet.final_results || [])[0])) { return null }
    return _.find(textPickPresenter.answers(bet), (answer) => { return answer.id === bet.final_results?.[0] }) || null
  },
  sport: (bet: TextSelection.Api.Bet) => {
    return bet.sport || "default"
  }
}
