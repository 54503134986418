import I18n from "i18n-js"
import React, { useEffect, useState } from "react"

const getInitialOpenState = () => {
  try {
    const initiallyClosed = window.localStorage.getItem("__communityStats__scoringExpl__closed") === "closed"
    return initiallyClosed ? false : true
  } catch(err) {}
  return true
}

const CommunityStatsExplanation: React.FC<{rulesPath: string}> = ({rulesPath}) => {
  const [isOpen, __setIsOpen] = useState(false)
  const toggle = () => {
    __setIsOpen((prev) => {
      try {
        window.localStorage.setItem("__communityStats__scoringExpl__closed", prev ? "closed" : "open")
      } catch(err) {
      }
      return !prev
    })
  }
  useEffect(() => {
    __setIsOpen(getInitialOpenState())
  }, [])
  return <div className={`communityStats__scoringExpl communityStats__scoringExpl--${isOpen ? "open" : "closed"} communityStats__theme`}>
    <h3 onClick={() => toggle()}>
      <span className="communityStats__asterisk">*</span>
      {" "}
      {I18n.t("community.member_ranking.points_info_title")}
    </h3>
    {isOpen && <p>
      {I18n.t("community.member_ranking.points_info_desc")}
      <br />
      <a href={rulesPath}>{I18n.t("community.member_ranking.points_info_link_to_rules")}</a>
    </p>}
  </div>
}

export default CommunityStatsExplanation
