import _ from 'lodash'

import pickPresenter from './pick'

const optionsPresenter = {
  allOptions: (bet) => { return bet.options || bet.riders || [] },
  possibleOptionsWithoutPicks: (bet) => {
    return _.filter(optionsPresenter.allOptions(bet), (option) => {
      return !_.some(bet.picks, (pick) => {
        if (!pickPresenter.isValidPick(pick)) { return false }
        return pick.id && ('' + pick.id === '' + (option.id || ''))
      })
    })
  },
  possibleOptionsWithoutPicksFromOtherPickers: (bet, ownPickerPick) => {
    return _.filter(optionsPresenter.allOptions(bet), (option) => {
      if (pickPresenter.isSame(ownPickerPick, option)) { return true }

      return !_.some(bet.picks, (pick) => {
        if (!pickPresenter.isValidPick(pick)) { return false }
        return pickPresenter.isSame(pick, option)
      })
    })
  },
  getOptionForId: (bet, id) => {
    return _.find(optionsPresenter.allOptions(bet), (option) => { return '' + option.id === '' + id }) || null
  }
}

export default optionsPresenter
