/*
  Sync structure with rails "button" partial!
*/

import React from 'react'

const Button = ({ className, primary, secondary, disabled, href, onClick, children, title, target, method, forceWhite, forceRed }) => {
  let variant = 'secondary'
  if (primary) variant = 'primary'
  if (disabled) variant = 'disabled'

  const methodAttributes = method ? { "data-method": method, rel: "nofollow" } : {}
  const targetAttr = target ? { target: target } : {}
  const classNames = [
    "button",
    `button--${variant}`,
    forceWhite ? "button--forceWhite" : "",
    forceRed ? "button--forceRed" : "",
    className || "",
  ].join(" ")

  if (disabled) {
    return (
      <span className={classNames}>
        <span className='button__title'>
          {title || children}
        </span>
      </span>
    )
  } else {
    return (
      <a className={classNames} href={href} onClick={onClick} {...targetAttr} {...methodAttributes}>
        <span className='button__title'>
          {title || children}
        </span>
      </a>
    )
  }
}

export default Button
