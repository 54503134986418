import React, { useContext } from 'react'
import I18n from 'i18n-js'

import BetContext from './context/bet'
import cancelledPresenter from './presenter/cancelled'

const CancelledInfo = ({ i18nNamespace = 'cancelled', cssNamespace = 'cancelledInfo', bet }) => {
  bet || (bet = useContext(BetContext))

  const babelPath = (key) => { return `${i18nNamespace}.${key}` }
  const cssClass = (cls) => { return `${cssNamespace}__${cls}` }

  return cancelledPresenter.isCancelled(bet) && (
    <div className={cssNamespace}>
      <div className={cssClass('wrapper')}>
        {cancelledPresenter.cancelledReason(bet) || I18n.t(babelPath('info'))}
      </div>
    </div>
  )
}

export default CancelledInfo
