import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import I18n from 'i18n-js'
import Button from './Button'

import modalSaveZone from '../misc/modal_save_zone'

function CommunityWelcomeModal ({
  community_name: communityName,
  community_membership_path: communityMembershipPath,
  authenticity_token: authenticityToken,
  invitation_token: invitationToken
}) {
  const [isOpen, setIsOpen] = useState(true)
  const [ saveZoneReady, setSaveZoneReady ] = useState(null)

  const formRef = useRef()

  useEffect(() => {
    modalSaveZone.onReady(() => {
      setSaveZoneReady(true)
    })
  }, [false])

  if (!saveZoneReady) return (null)

  const saveZone = modalSaveZone.calculate()

  const style = {
    // width: `${Math.min(400, saveZone.width)}px`,
    top: `${saveZone.centerY}px`,
    left: `${saveZone.centerX}px`,
    transform: `translate(-50%, -50%)`
  }

  const closeModal = () => setIsOpen(false)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.querySelector('#neoApp')}
      parentSelector={() => document.getElementById("neoApp")}
      overlayClassName="neo__modalOverlay"
      style={{
        content: style
      }}
      className='invitedModal'>
      <div className='invitedModal__close icon icon--close--rb-gray' onClick={closeModal} />
      <div className='invitedModal__header'>
        <h2 className='invitedModal__title'>
          {I18n.t('community.modal.welcome.title', { community_name: communityName })}
        </h2>
      </div>
      <div className='invitedModal__body'>
        <p className='invitedModal__text'>
          {I18n.t(`community.modal.welcome.text`)}
        </p>
        <form ref={formRef} method='post' action={communityMembershipPath}>
          <input type='hidden' name='authenticity_token' value={authenticityToken} />
          <input type='hidden' name='invitation_token' value={invitationToken} />
          <Button className='invitedModal__button' primary onClick={() => formRef.current?.submit()}>
            {I18n.t('community.modal.welcome.button')}
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export default CommunityWelcomeModal
