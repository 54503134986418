import React from 'react'
import { components } from 'react-select'
import cx from 'classnames'

const { DropdownIndicator: ReactSelectDropdownIndicator } = components

// DUPLICATED: Also in BetPick/BetPickSelect/Desktop/select-component
export const DropdownIndicator = (props) => (
  <ReactSelectDropdownIndicator {...props}>
    <div
      data-testid='dropdown'
      className={
        cx(
          'select__dropdownIndicator', {
            'select__dropdownIndicator--open': props.selectProps.menuIsOpen
          }
        )
      }
    />
  </ReactSelectDropdownIndicator>
)
