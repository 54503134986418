import React, { useContext } from 'react'
import _ from 'lodash'

import PickContext from '@root/bet_utils/context/pick'
import BetContext from '@root/bet_utils/context/bet'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import pickPresenter from '@root/bet_utils/presenter/pick'
import imagePresenter from '@root/bet_utils/presenter/image'

const InnerImage = ({ item = null, thumb = false }) => {
  const bet = useContext(BetContext)
  item || (item = useContext(PickContext))

  if (pickPresenter.isValidPick(item)) {
    if (imagePresenter.useFlagInstead(item.imageSrc)) {
      // FLAG
      return (
        <div className='circleImg__flag__container'>
          <div className={`flag__asBg flag__asBg--${item.countryCode} circleImg__flag ${thumb ? 'circleImg__flag--thumb' : ''}`} />
        </div>
      )
    } else {
      // IMAGE
      return (
        <img src={(thumb && item.thumbSrc) || item.imageSrc} />
      )
    }
  } else {
    // ICON
    const icon = betStatusPresenter.canEdit(bet)
      ? 'icon--betPick--pick' // +
      : (betStatusPresenter.isCensored(bet)
        ? 'icon--betPick--censored' // ?
        : 'icon--betPick--none') // -
    return (
      <div className={`circleImg__icon ${icon} ${betStatusPresenter.canEdit(bet) ? 'circleImg__icon--canEdit' : ''}`} />
    )
  }
}

const CircleIconPick = (props) => {
  return (
    <CircleImg {...props}>
      <div className={'circleImg__icon icon--betPick--pick circleImg__icon--canEdit'} />
    </CircleImg>
  )
}

const CircleIconNone = (props) => {
  return (
    <CircleImg {...props}>
      <div className={'circleImg__icon icon--betPick--none'} />
    </CircleImg>
  )
}

const CircleIconCensored = (props) => {
  return (
    <CircleImg {...props}>
      <div className={'circleImg__icon icon--betPick--censored'} />
    </CircleImg>
  )
}

const CircleImg = ({ item = null, thumb = false, onClick = null, variant, variants, children }) => {
  const cls = [
    'circleImg__wrapper',
    (variant && variant.length > 0) ? `circleImg__wrapper--${variant}` : '',
    variants ? _.map(variants, (v) => { return `circleImg__wrapper--${v}` }).join(' ') : '',
    thumb ? 'circleImg__wrapper--thumb' : ''
  ].join(' ')

  return (
    <div className={cls} onClick={onClick}>
      <div className='circleImg__wrapper__sizer' />
      <div className='circleImg__wrapper__content'>
        {children || <InnerImage item={item} thumb={thumb} />}
      </div>
    </div>
  )
}

export { CircleIconPick, CircleIconNone, CircleIconCensored }

export default CircleImg
