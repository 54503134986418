import React from "react"

import ThemeToggle from "./ThemeToggle"

const PageFooter = ({items, imprint, debug}) => {
  return <footer>
    <div className="pageFooter__wrapper">
      <ul className="pageFooter__navigation">
        {items.map((item) => {
          return <li key={item.title} className="pageFooter__navigation__item">
            <a className="pageFooter__navigation__link" href={item.href} target="_blank">{item.title}</a>
          </li>
        })}
      </ul>
      <div className="pageFooter__themeToggleHolder">
        <ThemeToggle debug={debug} />
      </div>
      <div className="pageFooter__imprint">
        <div className="pageFooter__imprint__content">
          <a href={imprint.link} target="_blank">
            <img className="pageFooter__logo" src={imprint.image.src} alt={imprint.image.alt} />
          </a>
          <p className="pageFooter__imprint__description" dangerouslySetInnerHTML={{__html: imprint.description}} />
        </div>
      </div>
    </div>
  </footer> 
}

export default PageFooter