import { signalFor, ctaInteraction } from './signals'
import { elementClosestPolyfill } from '@root/misc/dom_helper'

elementClosestPolyfill()

// Listen to "clicks" on targets with attr 'track-on-click', then dispatch signal
// (Using 'mousedown' as trigger, so the chances that requests before page-unloading go out are higher)
document.addEventListener('mousedown', (evt) => {
  const closestWithTrackOnClick = getClosestWithAttr(evt.target, 'track-on-click')
  if (closestWithTrackOnClick) {
    const payload = JSON.parse(closestWithTrackOnClick.getAttribute('track-on-click'))
    signalFor(payload.type).dispatch(payload)
  } else {
    const closestButton = evt.target.closest('.button')
    if (closestButton) {
      ctaInteraction.dispatch({ el: evt.target })
    }
  }
}, false)

const getClosestWithAttr = (el, attr) => {
  let cur = el
  while (cur) {
    if (cur.hasAttribute && cur.hasAttribute(attr)) { return cur }
    const parent = cur.parentNode
    cur = cur !== parent ? parent : null
  }
}
