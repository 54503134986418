import React from 'react'
import { useDrop } from 'react-dnd'

import { cardDragType } from './DragCard'

const DropCard = ({ onDrop, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: cardDragType,
    drop: onDrop,
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver()
      }
    }
  })
  return (
    <span
      ref={drop}
      style={{
        display: 'inline-block',
        width: '100px',
        margin: '.25em',
        background: isOver ? '#ddd' : '#ccc',
        textAlign: 'center',
        padding: '.5em .25em'
      }}>{children}</span>
  )
}

export default DropCard
