import React from 'react'
import cx from 'classnames'
import I18n from 'i18n-js'

import BetContext from '../context'

import BetPickCircle from './BetPickCircle'
import BetPickSelect from './BetPickSelect'

import { formatPlace } from '../utils'

class BetPickInner extends React.Component {
  constructor (props) {
    super(props)

    this.changePick = this.changePick.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)

    this.betPickSelectRef = React.createRef()
  }

  render () {
    const { place, pick, pickId, riders, value } = this.props
    const { fromOther, censored } = value

    const placeFormated = formatPlace(place)
    const hasPick = !!(pick && pick !== 0)

    const nameClass = cx(
      'bet__name',
      { 'flag': hasPick && pick.countryCode },
      hasPick && pick.countryCode ? `flag--${pick.countryCode}` : '',
      hasPick && pick.name ? '' : 'bet__name--empty'
    )

    const isOpen = value.eventState === 'open'
    const isOver = value.eventState === 'over'
    const showSelector = isOpen && !fromOther

    return (
      <div className={`bet__player bet__player--${placeFormated}`} data-testid='BetPickInner'>
        <BetPickCircle
          eventType={value.eventType}
          pick={pick}
          fromOther={fromOther}
          censored={censored}
          open={isOpen}
          className={
            cx(`circle--${placeFormated}`,
              { 'circle--withHover': isOpen && !fromOther && !hasPick },
              { 'pointer': isOpen && !fromOther },
              { 'circle--greyBorder': !isOpen || fromOther }
            )}
          onClick={this.toggleMenu}
          placeFormated={placeFormated}
        />
        <div className='bet__place' data-testid='place'>{I18n.t('bet.place.full', { place_formated: placeFormated })}</div>
        {showSelector &&
          <div className='bet__selectPlayer'>
            <BetPickSelect
              ref={this.betPickSelectRef}
              name={(place - 1)}
              place={place}
              onChange={this.changePick}
              riders={riders}
              value={pick}
              pickId={pickId}
            />
          </div>}
        {!showSelector &&
          <span className={nameClass} data-testid='name' >{pick.name || '-'}</span>}
        {isOver &&
          <div className='points points--inline'>
            {this.point(pick.score >= 1)}
            {this.point(pick.score >= 2)}
            {this.point(pick.score >= 3)}
          </div>}
      </div>
    )
  }

  point (scoredCondition) {
    return (
      <span className={cx('points__point points__point--size-big', { 'points__point--scored': scoredCondition })} />
    )
  }

  changePick (pick, action) {
    this.props.onChangePick(pick, action.name)
  }

  toggleMenu () {
    if (this.betPickSelectRef && this.betPickSelectRef.current) {
      this.betPickSelectRef.current.toggleMenu()
    }
  }
}

const BetPick = (props) => {
  return (
    <BetContext.Consumer>
      {(value) => (<BetPickInner value={value} pick={value.picks[props.pickId] || 0} {...props} />)}
    </BetContext.Consumer>
  )
}

export default BetPick
