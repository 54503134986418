import React from 'react'
import cx from 'classnames'
import { circleIcon } from '../../utils'

const BetPickCircle = ({ pick, thumb, className, eventType, onClick, fromOther, censored, open }) => {
  thumb || (thumb = false)

  const bg = eventType
  const hasPick = !!(pick && pick !== 0) // We need this bool-cast, otherwise React renders a "0" below

  // image or flag
  const imageSrc = hasPick && ((thumb && pick.thumbSrc) || pick.imageSrc)
  const useFlag = imageSrc && imageSrc.indexOf('DEFAULT') > -1
  const flag = useFlag ? pick.countryCode : ''

  // if no pick, display '+' or '-' or '?'
  let noPickIconClass = circleIcon.pick()
  if (censored && open) {
    noPickIconClass = circleIcon.censored()
  } else if (fromOther || !open) {
    noPickIconClass = circleIcon.none()
  }

  return (
    <div className={cx(`circle`, className)} onClick={onClick}>
      {hasPick &&
        <React.Fragment>
          <div
            className={
              cx('circle__inner',
                `circle__inner--bg-${bg}`
              )
            } />
          {!useFlag && <img className='circle__image' src={imageSrc} />}
          {useFlag && <div className={`circle__flag ${thumb ? 'circle__flag--thumb' : ''} flag__asBg flag__asBg--${flag}`} />}
        </React.Fragment>
      }
      {!hasPick &&
        <div className={`circle__inner circle__inner--icon icon ${noPickIconClass}`} />
      }
    </div>
  )
}

export default BetPickCircle
