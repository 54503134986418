import React from 'react'

import CircleImg from '../CircleImg'
import Name from '../Name'

const SelectItem = ({ item, select }) => {
  return (
    <div className='selector__selectItem' onClick={select}>
      <CircleImg item={item} thumb />
      <Name item={item} />
    </div>
  )
}

export default SelectItem
