document.addEventListener('DOMContentLoaded', () => {
  let query = document.querySelector('.filterForm__header #query')

  if (query) {
    query.addEventListener('input', () => {
      document.querySelectorAll('.js-reset-filter-communities').forEach((el) => {
        if (query.value.length > 0) {
          el.style.display = 'block'
        } else {
          el.style.display = 'none'
        }
      })
    })

    document.querySelectorAll('.js-reset-filter-communities').forEach((el) => {
      if (query.value.length > 0) {
        el.style.display = 'block'
      } else {
        el.style.display = 'none'
      }

      el.addEventListener('click', () => {
        let query = document.querySelector('#query')
        let form = document.querySelector('.filterCommunities')

        if (query) {
          query.value = ''
          form.submit()
        }
      })
    })
  }
})
