/* global Element */

const elementClosestPolyfill = (() => {
  let called = false
  return () => {
    if (!called) {
      called = true

      if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
      }

      if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
          var el = this

          do {
            if (el.matches(s)) return el
            el = el.parentElement || el.parentNode
          } while (el !== null && el.nodeType === 1)
          return null
        }
      }
    }
  }
})()

const isSubmitOrButton = (el) => {
  const tagName = el.tagName.toLowerCase()
  if (tagName !== 'input') { return false }

  const tp = el.getAttribute('type').toLowerCase()
  if (tp === 'submit') { return true }
  if (tp === 'button') { return true }
  return false
}

export { elementClosestPolyfill, isSubmitOrButton }
