import React from 'react'
import { Transition } from 'react-transition-group'

class FlashMessage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      showMessage: false
    }

    this._defaultStyle = {
      transition: `opacity ${props.durationToShow}ms`,
      opacity: 0
    }

    this._transitionStyles = {
      entering: {
        opacity: 0
      },
      exiting: {
        transition: `opacity ${props.durationToHide}ms`
      },
      entered: { opacity: 1 }
    }

    this._onEntered = this._onEntered.bind(this)
  }

  show () {
    if (this._intervalId !== undefined) {
      this._clear()
    }

    this.setState({ showMessage: true })
  }

  render () {
    const { children, durationToShow, durationToHide } = this.props

    return (
      <Transition
        in={this.state.showMessage}
        onEntered={this._onEntered}
        timeout={{
          enter: durationToShow,
          exit: durationToHide
        }}
      >
        {state => (
          <div style={{
            ...this._defaultStyle,
            ...this._transitionStyles[state]
          }}>
            {children}
          </div>
        )}
      </Transition>
    )
  }

  _onEntered () {
    this._intervalId = window.setTimeout(() => {
      this._clear()
    }, this.props.durationToStay)
  }

  _clear () {
    this.setState({ showMessage: false })
    clearTimeout(this._intervalId)
    this._intervalId = undefined
  }
}

FlashMessage.defaultProps = {
  durationToShow: 400,
  durationToStay: 3000,
  durationToHide: 300
}

export default FlashMessage
