import _ from 'lodash'

const allOptions = (bet) => {
  return bet.options || bet.riders || []
}

const optionForId = (bet, id) => {
  return _.find(allOptions(bet), (option) => { return '' + option.id === '' + id }) || null
}

const isSameOption = (option1, option2) => {
  return option1 && option1.id && option2 && option2.id && option1.id === option2.id
}

export { allOptions, optionForId, isSameOption }
