import React, { useContext, useState } from "react"
import I18n from "i18n-js"
import _ from "lodash"

// @ts-ignore
import BetContext from '@root/bet_utils/context/bet'
// @ts-ignore
import Icon from '@root/components/Icon'

const Correctness: React.FC<{ fulfilled: boolean, text: string, points: any }> = ({ fulfilled, text, points }) => {
  return <div className={`scoreBet__correctness ${fulfilled ? '--fulfilled' : '--notFulfilled'}`}>
    <span><Marker correct={fulfilled} />{text}</span>
    <span>{fulfilled ? `+${points}` : '-'}</span>
  </div>
}

const Marker: React.FC<{correct?: boolean}> = ({correct}) => {
  return <Icon type={correct ? "scoreBet__detailScore__markCorrect" : "scoreBet__detailScore__markWrong"} inlineWithText />
}

const Result: React.FC = () => {
  const bet: any = useContext(BetContext)
  const [ state, setState ] = useState<{collapsed: boolean}>({collapsed: true})

  return <>
  <div aria-label={I18n.t("bet.scoring.final_aria_toggle_details")} className='scoreBet__finalResult' onClick={() => setState({...state, collapsed: !state.collapsed})}>
    <h3>
      {I18n.t('bet.scoring.final_title')}
      {bet.scores &&
        <span className="scoreBet__finalResult__correctnessMarkers">
          {_.map(_.keys(bet.scores), (key, index) => {
            return <Marker key={index} correct={!!bet.scores[key]} />
          })}
        </span>}
      {bet.scores &&
          <Icon type={state.collapsed ? "ui__textArrowDown" : "ui__textArrowUp"} inlineWithText />}
    </h3>
    <div>{bet.final_results.join(' : ')}</div>
  </div>
  {!state.collapsed && bet.scores &&
    <div className='scoreBet__correctnesses'>
      {_.map(_.keys(bet.scores), (key) => {
        const value = bet.scores[key]
        return <Correctness
          key={key}
          text={I18n.t('scoring_info.' + key)}
          fulfilled={!!value}
          points={value} />
      })}
    </div>}
  </>
}

export default Result
