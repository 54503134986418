import React, { useCallback, useState } from 'react'
import { isMobile } from 'is-mobile'
import I18n from 'i18n-js'
import ReCaptcha from "react-google-recaptcha"
import axios from "axios"

// @ts-ignore
import CopyToClipboard from './CopyToClipboard'
// @ts-ignore
import { interactWithSocialLink } from '@root/tracking/signals'
// @ts-ignore
import Button from "./Button"
// @ts-ignore
import Icon from "./Icon"
import { useTheme } from "@root/misc/theme"

declare global {
  interface Window {
    __NEO_WEBVIEW?: {
      available: boolean
      share: (shareOpts: {
        title: string
        message: string
        url: string
      }) => void
    }
  }
}

const iconClass = (icon: string) => {
  return `icon icon--${icon} icon--withBg`
}

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const isValid = (addr: string) => {
  return emailRegex.test(addr)
}

const allValid = (addrs: string[]) => {
  return addrs.every((addr) => isValid(addr))
}

const removeEmpty = (addresses: string[]) => {
  return addresses.filter((addr) => addr !== "")
}

interface PopupProps {
  close: () => void
  url: string
  subject: string
  body: string
  authenticity_token: string
  setShowMailShareSuccess: (success: boolean) => void
  recaptcha_public_secret: string
}

const MailPopup: React.FC<PopupProps> = ({close, url, subject, body, authenticity_token, setShowMailShareSuccess, recaptcha_public_secret}) => {
  const [token, setToken] = useState<string | null>(null)
  const [addresses, setAddresses] = useState([""])
  const [personalMessage, setPersonalMessage] = useState(`${subject}\n${body}\n`)
  const [error, setError] = useState({isError: false, message: ""})
  const [invalid, setInvalid] = useState(false)
  const {theme, ref} = useTheme()
  const send = useCallback(() => {
    setError({isError: false, message: ""})
    setInvalid(false)
    const nonEmptyAddrs = removeEmpty(addresses)
    const isAllValid = allValid(nonEmptyAddrs)
    if (nonEmptyAddrs.length === 0) {
      setError({
        isError: true,
        message: "communities.share.mail.no_recipient",
      })
    } else if (!isAllValid) {
      setInvalid(true)
    } else  {
      if (token) {
        console.log("Sending...", {url, emails: nonEmptyAddrs.join(","), personal_message: personalMessage, authenticity_token})
        axios.post(url, {
          emails: nonEmptyAddrs.join(","),
          personal_message: personalMessage,
          recaptcha_response: token,
          authenticity_token,
        })
        .then(() => {
          close()
          setShowMailShareSuccess(true)
        })
        .catch((error) => {
          console.warn("Failed with error:", error)
          const message = error?.response?.data?.error
          if (message) {
            setError({isError: true, message})
          } else {
            setError({isError: true, message: "communities.share.mail.sending_failed"})
          }
        })
      } else {
        setError({
          isError: true,
          message: "communities.share.mail.recaptcha_missing",
        })
      }
    }
  }, [token, addresses, personalMessage])
  const setAddress = useCallback((index, value) => {
    const newAddresses = addresses.concat()
    newAddresses[index] = value
    setAddresses(newAddresses)
  }, [addresses])
  const removeAddress = useCallback((index) => {
    const newAddresses = addresses.concat()
    newAddresses.splice(index, 1)
    setAddresses(newAddresses)
  }, [addresses])
  return (
    <div ref={(node) => ref.current = node} className="shareIcons__mailPopup">
      <div className="shareIcons__mailPopup__inputs">
        <label>{I18n.t("communities.share.mail.recipients")}</label>
        {
          addresses.map((value, index) => {
            return <div key={`address ${index}`} className="shareIcons__mailPopup__inputs__row">
              <input
                className="input"
                type="email"
                onBlur={(e) => {
                  if (isValid(e.target.value) || e.target.value === "") {
                    e.target.classList.remove("input--invalid")
                  } else {
                    e.target.classList.add("input--invalid")
                  }
                }}
                placeholder={I18n.t("communities.share.mail.placeholder")}
                value={value}
                onChange={(e) => setAddress(index, e.target.value)}
              />
              {index != 0 && <div onClick={() => removeAddress(index)}><Icon type="ui__textCross" /></div>}
            </div>
          })
        }
        {invalid && <div className="shareIcons__mailPopup__error">{I18n.t("communities.share.mail.invalid")}</div>}
        <Button primary disabled={addresses.some((addr) => !isValid(addr))} onClick={() => setAddresses([...addresses, ""])}>{I18n.t("communities.share.mail.add_address")}</Button>
        <label>{I18n.t("communities.share.mail.personal_message")}</label>
        <textarea className="input" style={{height: "auto"}} rows={10} value={personalMessage} onChange={(e) => setPersonalMessage(e.target.value)}/>
      </div>
      <ReCaptcha hl={I18n.currentLocale()} theme={theme} sitekey={recaptcha_public_secret} onChange={setToken} />
      <div className="shareIcons__mailPopup__buttons">
        <Button primary onClick={send}>{I18n.t("communities.share.mail.btn_share")}</Button>
        <Button secondary onClick={close}>{I18n.t("communities.share.mail.btn_cancel")}</Button>
      </div>
      {error.isError && <div className="shareIcons__mailPopup__error">{I18n.t(error.message)}</div>}
    </div>
  )
}

interface ShareIconsProps {
  whatsapp_url: string
  facebook_url: string
  twitter_url: string
  profile_url?: string
  url_to_copy: string
  mail_url: string
  mail_subject: string
  mail_body: string
  reddit_url?: string
  authenticity_token: string
  setShowMailShareSuccess: (success: boolean) => void
  recaptcha_public_secret: string
}

interface ShareIconsState {
  isMobile: boolean
  showLink: boolean
  mailPopupOpen: boolean
  justCopied: boolean
  justCopiedTimeout: NodeJS.Timeout | null
}

class ShareIconsFragment extends React.Component<ShareIconsProps, ShareIconsState> {
  constructor (props: ShareIconsProps) {
    super(props)
    this.state = {
      isMobile: isMobile({ tablet: true }),
      showLink: false,
      mailPopupOpen: false,
      justCopied: false,
      justCopiedTimeout: null
    }
    this.toggleShowLink = this.toggleShowLink.bind(this)
    this.toggleMail = this.toggleMail.bind(this)
    this.justCopied = this.justCopied.bind(this)
    this.afterJustCopiedTimeout = this.afterJustCopiedTimeout.bind(this)
  }

  afterJustCopiedTimeout () {
    this.setState({
      justCopied: false,
      justCopiedTimeout: null
    })
  }

  justCopied () {
    if (this.state.justCopiedTimeout) {
      clearTimeout(this.state.justCopiedTimeout)
    }
    const timeout = setTimeout(this.afterJustCopiedTimeout, 2000)

    this.setState({
      justCopied: true,
      justCopiedTimeout: timeout
    })
  }

  componentWillUnmount () {
    if (this.state.justCopiedTimeout) {
      clearTimeout(this.state.justCopiedTimeout)
    }
  }

  toggleShowLink () {
    this.setState({
      showLink: !this.state.showLink
    })
  }

  toggleMail () {
    this.setState({
      mailPopupOpen: !this.state.mailPopupOpen
    })
  }

  render () {
    const whatsappUrl = this.props.whatsapp_url
    const facebookUrl = this.props.facebook_url
    const twitterUrl = this.props.twitter_url
    const urlToCopy = this.props.profile_url || this.props.url_to_copy
    const mailUrl = this.props.mail_url
    const mailSubject = this.props.mail_subject
    const mailBody = this.props.mail_body
    const redditUrl = this.props.reddit_url
    const authenticity_token = this.props.authenticity_token
    const setShowMailShareSuccess = this.props.setShowMailShareSuccess
    const recaptcha_public_secret = this.props.recaptcha_public_secret

    return (
      <React.Fragment>
        {!(this.state.showLink || this.state.mailPopupOpen) &&
          (window.__NEO_WEBVIEW && window.__NEO_WEBVIEW.available
            ? <a
              key='nativeAppShare'
              onClick={() => {
                interactWithSocialLink.dispatch('Native App Share')
                window.__NEO_WEBVIEW!.share({
                  title: mailSubject,
                  message: mailBody,
                  url: urlToCopy
                })
              }}
              className={iconClass('nativeAppShare')}>Share</a>
            : <React.Fragment>
              {this.state.isMobile &&
                <a key='whatsapp' onClick={() => { interactWithSocialLink.dispatch('Whatsapp') }} className={iconClass('whatsapp')} target='_blank' href={whatsappUrl}>WhatsApp</a>
              }
              <a key='facebook' onClick={() => { interactWithSocialLink.dispatch('Facebook') }} className={iconClass('facebook')} target='_blank' href={facebookUrl}>Facebook</a>
              <a key='twitter' onClick={() => { interactWithSocialLink.dispatch('Twitter') }} className={iconClass('twitter')} target='_blank' href={twitterUrl}>Twitter</a>
              {redditUrl &&
                <a key='reddit' onClick={() => { interactWithSocialLink.dispatch('Reddit') }} className={iconClass('reddit')} target='_blank' href={redditUrl}>Reddit</a>}
              <a key='mail' onClick={() => { interactWithSocialLink.dispatch('Mail'); this.toggleMail() }} className={iconClass('email')}>Mail</a>
              <a key='link' onClick={() => { interactWithSocialLink.dispatch('Direct link'); this.toggleShowLink() }} className={iconClass('link')} target='_blank'>Copy Link</a>
            </React.Fragment>)
        }
        {this.state.showLink &&
          <div className='shareIcons__copyToClipboard__wrapper'>
            <CopyToClipboard text={urlToCopy} onClose={this.toggleShowLink} />
            <div className='shareItons__copyToClipboard__title'>{I18n.t('share.copy_link')}</div>
          </div>
        }
        {this.state.mailPopupOpen &&
          <MailPopup
            close={this.toggleMail}
            url={mailUrl}
            subject={mailSubject}
            body={mailBody}
            authenticity_token={authenticity_token}
            setShowMailShareSuccess={setShowMailShareSuccess}
            recaptcha_public_secret={recaptcha_public_secret}
          />
        }
      </React.Fragment>
    )
  }
}

const ShareIcons: React.FC<ShareIconsProps> = (props) => {
  const [showMailShareSuccess, setShowMailShareSuccess] = useState(false)
  return (
    <>
      <div className='shareIcons'>
        <ShareIconsFragment {...{...props, setShowMailShareSuccess}} />
      </div>
      {showMailShareSuccess && <div className="shareIcons__ok">{I18n.t("communities.share.mail.sending_succeeded")}</div>}
    </>
  )
}

export { ShareIconsFragment as Fragment }
export default ShareIcons
