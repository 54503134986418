import React, { useContext, useRef } from 'react'
import _ from 'lodash'

import SetNewPickIdContext from '@root/bet_utils/context/set_new_pick_id'
import BetContext from '@root/bet_utils/context/bet'
import PickContext from '@root/bet_utils/context/pick'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import pickPresenter from '@root/bet_utils/presenter/pick'
import optionsPresenter from '@root/bet_utils/presenter/options'
import Name, { NamePlaceholder } from '@root/bet_utils/Name'
import CircleImg, { CircleIconPick, CircleIconNone, CircleIconCensored } from '../CircleImg'
import Selector from '../Selector'
import Icon from '@root/components/Icon'

const CirclePicker = ({ pickerLabel, itemIndex }) => {
  const setNewPickId = useContext(SetNewPickIdContext)
  const bet = useContext(BetContext)
  const pick = useContext(PickContext)
  const selectorRef = useRef(null)
  const canEdit = betStatusPresenter.canEdit(bet)
  const imgOnClick = () => { canEdit && selectorRef.current && selectorRef.current.open() }

  let PickImage
  let PickName
  if (canEdit) {
    // own and open
    if (pickPresenter.isValidPick(pick)) {
      PickImage = <CircleImg item={pick} onClick={imgOnClick} variants={['canEdit', 'shadowed']} />
    } else {
      PickImage = <CircleIconPick onClick={imgOnClick} variants={['canEdit', 'shadowed']} />
    }
    PickName = <Selector ref={selectorRef} current={pick} items={optionsPresenter.possibleOptionsWithoutPicksFromOtherPickers(bet, pick)} setNewPickId={setNewPickId}/>
  } else {
    // not editable
    if (pickPresenter.isValidPick(pick)) {
      PickImage = <CircleImg item={pick} variants={['shadowed']} />
      PickName = <div className='circlePicker__nameNotEditable'><Name item={pick} /></div>
    } else {
      if (betStatusPresenter.isCensored(bet)) {
        // censored
        PickImage = <CircleIconCensored variants={['shadowed']} />
        PickName = <div className='circlePicker__nameNotEditable'><NamePlaceholder text={'?'} /></div>
      } else {
        PickImage = <CircleIconNone variants={['shadowed']} />
        PickName = <div className='circlePicker__nameNotEditable'><NamePlaceholder text={'-'} /></div>
      }
    }
  }

  return (
    <div className={`circlePicker circlePicker--index${itemIndex}`}>
      {PickImage}
      {pickerLabel}
      {PickName}
      {betStatusPresenter.isMatchOverAndScored(bet) &&
        <React.Fragment>
          {false && <Points score={pick.score} />}
          {true && <PointsAsDigits score={pick.score} />}
        </React.Fragment>}
    </div>
  )
}

const PointsAsDigits = ({ score }) => {
  score || (score = 0)
  const isZero = score === 0

  return (
    <div className={`circlePicker__score circlePicker__score--${isZero ? 'zero' : 'nonZero'}`}>
      <span className='circlePicker__score__value'>{score}</span>
      <Icon type={isZero ? 'point--default' : 'point--gained'} />
    </div>
  )
}

const Points = ({ score }) => {
  return (
    <div className='points points--inline'>
      <Point gained={score >= 1} />
      <Point gained={score >= 2} />
      <Point gained={score >= 3} />
    </div>
  )
}

const Point = ({ gained }) => {
  return (
    <span className={`points__point points__point--size-big ${gained ? 'points__point--scored' : ''}`} />
  )
}

const Wrapper = ({ className, children }) => {
  return (
    <div className={`${className || ''} circlePicker__pickerContainer`}>
      {children}
    </div>
  )
}

export default CirclePicker
export { Wrapper, PointsAsDigits }
