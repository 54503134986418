document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-toggle-edit-view').forEach((el) => {
    el.addEventListener('click', (evt) => {
      const currentTarget = evt.currentTarget
      const form = document.querySelector(`#${currentTarget.getAttribute('data-editid')}`)

      document.querySelectorAll('.EditMemberForm').forEach((el, index) => {
        if (form !== el) {
          el.classList.add('hidden')
        }
      })

      form.classList.toggle('hidden')
    })
  })

  document.querySelectorAll('.toggle-location').forEach((el) => {
    el.addEventListener("click", function onclick(event) {
      event.preventDefault()
      window.location.href = this.dataset.path
    })
  })
})
