
import cssVar from '@styles/_export'

const selectHeight = 48

const customStyles = (theme, isPhone) => {
  const dark = theme === "dark"
  const colors = dark
    ? {
        dropdownBg: cssVar.dropdownBg__dark,
        dropdownBorder: cssVar.dropdownBorder__dark,
        dropdownTxt: cssVar.dropdownTxt__dark,
        dropdownActiveTxt: cssVar.dropdownActiveTxt__dark,
      }
    : {
        dropdownBg: cssVar.dropdownBg,
        dropdownBorder: cssVar.dropdownBorder,
        dropdownTxt: cssVar.dropdownTxt,
        dropdownActiveTxt: cssVar.dropdownActiveTxt,
      }
  return {
    container: (provided) => ({
      ...provided,
      flex: '1 1',
      width: '100%'
    }),
    control: (provided, { menuIsOpen }) => {
      const borderRadius = 2

      return {
        ...provided,
        transition: 'none',
        borderRadius,
        borderBottomRightRadius: menuIsOpen ? 0 : borderRadius,
        borderBottomLeftRadius: menuIsOpen ? 0 : borderRadius,
        /*
          do not use borderBottomWidth. the component move a bit up and down if we change
          the borderWidth
        */
        borderBottomColor: menuIsOpen ? 'transparent' : colors.dropdownBorder,
        boxShadow: 'none',
        borderColor: colors.dropdownBorder,
        ':hover': {
          borderColor: colors.dropdownBorder,
          borderBottomColor: menuIsOpen ? 'transparent' : colors.dropdownBorder
        },
        cursor: 'pointer',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        color: colors.dropdownTxt,
        backgroundColor: colors.dropdownBg,
        minHeight: selectHeight
      }
    },
    menu: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      borderRadius: 0,
      border: 1,
      borderColor: colors.dropdownBorder,
      borderStyle: 'solid',
      width: '100%',
      minWidth: 230
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      marginRight: 10,
      color: colors.dropdownTxt,
      ':hover': {
        color: colors.dropdownTxt
      }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: colors.dropdownBg,
      color: state.isFocused ? colors.dropdownActiveTxt : colors.dropdownTxt,
      fontSize: isPhone ? '10pt' : '16pt',
      textAlign: 'center',
      cursor: 'pointer'
    }),
    valueContainer: (provided) => ({
      ...provided,
      ':hover': {
        color: colors.dropdownActiveTxt
      },
      fontSize: isPhone ? '14pt' : '16pt',
      justifyContent: 'center',
      marginRight: -45.6 // <-- hack to center the text correctly because of the indicator
    }),
    singleValue: (provided) => ({
      ...provided,
      position: 'relative',
      maxWidth: 'calc(100% - 85px)',
      width: '100%',
      transform: 'none',
      margin: isPhone ? '0 42px 0 -18px' : '0 42px',
      textAlign: 'center',
      color: colors.dropdownTxt
    }),
    indicatorSeparator: () => ({}),

    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap'
    })
  }
}

export default customStyles
