
import React from 'react'
import MediaQuery from 'react-responsive'
import cssVar from '@styles/_export'

import BetPickSelectDesktop from './Desktop'
import BetPickSelectPhone from './Phone'

const BetPickSelect = React.forwardRef((props, ref) => {
  const { breakpointTabletUp } = cssVar

  return (
    <MediaQuery query={`(max-width: ${parseInt(breakpointTabletUp) - 0.4}px)`}>
      {(matches) => {
        if (matches) {
          return <BetPickSelectPhone ref={ref} {...props} />
        } else {
          return <BetPickSelectDesktop ref={ref} {...props} />
        }
      }}
    </MediaQuery>
  )
})

export default BetPickSelect
