import React from 'react'
import I18n from 'i18n-js'

import Icon from './Icon'
import ShareIcons from './ShareIcons'

const BonusForSharing = ({ score = 0, share }) => {
  return (
    <div className='bonusForSharing'>
      <h3>{I18n.t('bonus_for_sharing.title')}</h3>
      <p>
        {I18n.t('bonus_for_sharing.info')}
      </p>
      {score === 0 &&
        <p className='bonusForSharing__noPoints'>{I18n.t('bonus_for_sharing.no_points')}</p>
      }
      {score > 0 &&
        <p>
          <span className='bonusForSharing__points'>
            +{score}
            <Icon type='point--gained' inlineWithText />
          </span>
        </p>}
      <p>
        {I18n.t('bonus_for_sharing.share_links_title')}
      </p>
      <div className='bonusForSharing__iconsWrapper'>
        <ShareIcons {...share} />
      </div>
    </div>
  )
}

export default BonusForSharing
