import React, { Fragment, useContext, useState, useEffect } from 'react'

import { PickContext, TeamContext } from './context'
import BetContext from '@root/bet_utils/context/bet'
import { pickPresenter } from './presenter'
import _ from 'lodash'
import I18n from 'i18n-js'

const killMouseDown = (evt) => {
  evt.nativeEvent.stopImmediatePropagation()
}

const Picker = ({ currentPick, onPick, onAbort }) => {
  // close picker when clicking outside (needs some hacks so iOS registeres the click)
  useEffect(() => {
    document.body.style.cursor = 'pointer'
    document.body.style.tapHighlightColor = 'transparent'
    document.body.style.webkitTapHighlightColor = 'transparent'
    document.addEventListener('mousedown', onAbort, false)
    return () => { // cleanup
      document.body.style.cursor = 'default'
      document.body.style.tapHighlightColor = 'unset'
      document.body.style.webkitTapHighlightColor = 'unset'
      document.removeEventListener('mousedown', onAbort)
    }
  })

  const Numbers = []
  for (let i = 0; i <= 9; i++) {
    const value = i // MUST copy i here, because of scope!
    const isCurrent = currentPick === value
    const classes = [
      'scoreBet__picker__number',
      'scoreBet__picker__number--' + value,
      isCurrent ? 'scoreBet__picker__number--current' : ''
    ]
    Numbers.push(
      <a className={classes.join(' ')} key={value} onClick={() => { (isCurrent && onAbort()) || onPick(value) }}>{value}</a>
    )
  }
  // Insert placeholder and closeIcon
  Numbers.splice(1, 0,
    <span key='placeholder' className='scoreBet__picker__placeholder' />,
    <a className='scoreBet__picker__closeIcon' key='close' onClick={onAbort}>X</a>
  )
  return (
    <div className='scoreBet__picker' onMouseDown={killMouseDown}>
      {Numbers}
    </div>
  )
}

const PickArrow = ({ increase, decrease, noTopLimit }) => {
  if (!increase && !decrease) {
    return null
  }

  const pick = useContext(PickContext)

  const variant = increase ? 'increase' : 'decrease'
  const enabled = increase ? (noTopLimit || pickPresenter.canIncrease(pick.value)) : pickPresenter.canDecrease(pick.value)
  const classes = [
    `scoreBet__pickArrow`,
    `scoreBet__pickArrow--${variant}`,
    enabled ? 'scoreBet__pickArrow--enabled' : 'scoreBet__pickArrow--disabled'
  ]

  return (
    <div
      className={classes.join(' ')}
      onClick={() => { pick.setValue(increase ? pickPresenter.increased(pick.value, noTopLimit) : pickPresenter.decreased(pick.value)) }} />
  )
}

const ScoreEditableViaPopup = () => {
  const bet = useContext(BetContext)
  const pick = useContext(PickContext)
  const [ showPicker, setShowPicker ] = useState(false)
  const allNumbers = []
  for (let i = -1; i <= pickPresenter.maxPick(); i++) {
    const num = i // MUST copy i here, because of scope!
    const isCurrent = num === pick.value
    allNumbers.push(
      <span key={num} className='scoreBet__pick__numberContainer'>
        <span className={'scoreBet__pick__number' + (isCurrent ? ' scoreBet__pick__number--current' : '')}>{pickPresenter.pretty(bet, num)}</span>
      </span>
    )
  }
  allNumbers.reverse()

  return (
    <>
      <div className={`scoreBet__editablePick scoreBet__editablePick--currentIs${pick.value}`} onMouseDown={() => setShowPicker(true)}>
        <div className='scoreBet__editablePick__allNumbers'>
          {allNumbers}
        </div>
      </div>
      {showPicker &&
        <Picker
          currentPick={pick.value}
          onPick={(newPick) => { setShowPicker(false); pick.setValue(newPick) }}
          onAbort={() => { setShowPicker(false) }} />}
    </>
  )
}

const onFocusSelectAllAndScrollIntoView = (evt) => {
  const el: HTMLInputElement = evt.target
  el.select()
  if (el.getBoundingClientRect().bottom > document.documentElement.clientHeight - 20) {
    window.scrollBy(0, 50)
  }
}

const ScoreEditableViaInput = () => {
  const pick = useContext(PickContext)
  const team = useContext(TeamContext)

  const onChange = (inputVal) => {
    try {
      const valAsNum = parseInt(inputVal)
      if (_.isNumber(valAsNum) && !_.isNaN(valAsNum)) {
        pick.setValue(valAsNum < 0 ? -valAsNum : valAsNum)
      }
      if (inputVal === "" && pick.value > -1) { pick.setValue(-1) }
    } catch {}
  }

  const val = pick.value === -1 ? "" : pick.value

  return (
    <div className="scoreBet__editablePickInputHolder">
      <input onFocus={onFocusSelectAllAndScrollIntoView} className="scoreBet__pick__number" type="tel" size="2" aria-label={I18n.t("bet.score.score-for-team-aria-label", {team: team?.name || ""})} maxLength="2" onChange={(evt) => onChange(evt.target.value)} value={val} />
    </div>
  )
}

const EditablePick = (_) => {
  return (
    <>
      <PickArrow increase noTopLimit/>
      <PickArrow decrease />
      <ScoreEditableViaInput />
    </>
  )
}

const FixedPick = (_) => {
  const bet = useContext(BetContext)
  const pick = useContext(PickContext)

  return (
    <div className='scoreBet__fixedPick'>
      <span className='scoreBet__pick__numberContainer'>
        <span className='scoreBet__pick__number'>{pickPresenter.pretty(bet, pick.value)}</span>
      </span>
    </div>
  )
}

export { FixedPick, EditablePick }
