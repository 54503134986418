import React, { useState } from 'react'
import LeaveCommunityModal from './LeaveCommunityModal'
import I18n from 'i18n-js'

import Button from "./Button"

function LeaveCommunityButton ({ community_name: communityName, leave_path: leavePath, authenticity_token: authenticityToken }) {
  const [isOpen, setModalOpen] = useState(false)

  return (
    <React.Fragment>
      <LeaveCommunityModal
        isOpen={isOpen}
        onClose={() => setModalOpen(false)}
        communityName={communityName}
        leavePath={leavePath}
        authenticityToken={authenticityToken} />
      <Button secondary role='button' onClick={() => setModalOpen(true)}>
        {I18n.t('community.your_membership.leave_the_community')}
      </Button>
    </React.Fragment>
  )
}

export default LeaveCommunityButton
