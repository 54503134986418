import I18n from 'i18n-js'

export function formatPlace (n) {
  if (n >= 1 && n <= 3) { return I18n.t('general.place.place_' + n) }
  return ''
}

export const filteredRiders = (riders, picks) => riders.filter((rider) => {
  // only TRUE for riders that are not picked
  for (let pick of picks) {
    if (pick !== null && pick !== 0) {
      if (pick.id === rider.id) {
        return false
      }
    }
  }
  return true
})

export function getEventTypeFromString (eventName) {
  if (eventName.match(/xco/i)) {
    return 'xco'
  }

  if (eventName.match(/dhi/i)) {
    return 'dhi'
  }

  return 'xcc' // fallback
}

export const circleIcon = {
  pick: () => { return 'icon--betPick--pick' },
  none: () => { return 'icon--betPick--none' },
  censored: () => { return 'icon--betPick--censored' }
}

export const eventIconClassForEventName = (name) => {
  switch (getEventTypeFromString(name)) {
    case 'dhi':
      return 'icon--dhi--rb-gray'
    case 'xcc':
    case 'xco':
      return 'icon--xco--rb-gray'
    default:
      return null
  }
}
