import React, { useState, useEffect } from 'react'
import modalSaveZone from '../misc/modal_save_zone'

const ModalSaveZoneDebugView = () => {
  const [ saveZone, setSaveZone ] = useState(null)

  useEffect(() => {
    modalSaveZone.onReady(() => {
      setSaveZone(modalSaveZone.calculate())
    })
    return () => { // cleanup
    }
  }, [false])

  if (!saveZone) return (null)

  const style = {
    position: 'absolute',
    zIndex: '9999999999',
    background: 'transparent',
    border: '1px solid aquamarine',
    width: `${Math.min(400, saveZone.width)}px`,
    height: `${saveZone.height}px`,
    top: `${saveZone.centerY}px`,
    left: `${saveZone.centerX}px`,
    transform: `translate(-50%, -50%)`
  }

  return (
    <div style={style}>
      <ul>
        <li>{saveZone.centerX}</li>
        <li>{saveZone.centerY}</li>
        <li>{saveZone.width}</li>
        <li>{saveZone.height}</li>
      </ul>
    </div>
  )
}

export default ModalSaveZoneDebugView
