// @ts-ignore
import cssVar from '@styles/_export'
import { useMediaQuery } from "react-responsive"

export const useDevice = () => {
  const isPhone = useMediaQuery({ query: `(max-width: ${parseInt(cssVar.breakpointTabletUp) - 0.4}px)`})
  return {
    isPhone,
    isTabletUp: !isPhone
  }
}
