/*
  RedBull loads a custom script (that is also created by us in /ui/redbull_custom_script) that integrates our app into their page via IFrame

  The app has to notify the outer page (window.parent) about the content size, so the IFrame height can be set correctly.

  Also, the app gets info about the offset of the iframe and the window height of the containing redbull page (needed for centering modals inside iframe)
*/

const MESSAGE = { // copy-paste between /ui/redbull_custom_script/src/index.js
  SET_HEIGHT: '__customScript__setHeight:',
  NOTIFY_IFRAME_PLACEMENT: '__customScript__notifyIframePlacement:'
}

const notifyParentOfHeight = (height) => {
  if (window.parent !== window) {
    window.parent.postMessage(MESSAGE.SET_HEIGHT + height, '*')
  }
}

const onResize = () => {
  if (!document.body) return
  notifyParentOfHeight(document.body.clientHeight + 40)
}

if (window.parent !== window) {
  window.addEventListener('resize', onResize) // update height on resize
  window.addEventListener('load', onResize) // initial resize
}

// ---------------- update height on DOM mutations ----------------
const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
if (MutationObserver) {
  let observer = new MutationObserver(function (mutations) {
    onResize()
  })

  observer.observe(document, { childList: true, subtree: true })

  let _onbeforeunload = window.onbeforeunload

  window.onbeforeunload = function (evt) {
    _onbeforeunload && _onbeforeunload(evt)
    observer.disconnect()
  }
} else {
  // Fallback
  window.setInterval(function () {
    onResize()
  }, 2000)
}
// ---------------- / update height on DOM mutations ----------------

// ---------------- embedding frame tells app about iframe offset an parent size ----------------
const __iframePlacement = { topOffset: 0, parentHeight: 0 }
let __iframePlacementProperlyReceived = false
const __whenIframePlacementProperlyReceived = []
const fillIframePlacementFromString = (str) => {
  const payload = str.split(',')
  __iframePlacement.topOffset = parseInt(payload[0])
  __iframePlacement.parentHeight = parseInt(payload[1])
}
const getIframePlacement = () => {
  if (!__iframePlacementProperlyReceived) {
    // extract from url
    if (window.location.href.indexOf('iframePlacement=') > -1) {
      fillIframePlacementFromString(window.location.href.split('iframePlacement=')[1])
    } else {
    }
  }
  return { ...__iframePlacement }
}

const receiveMessage = function (evt) {
  if (!evt || !evt.data || !(typeof evt.data === 'string')) return
  if (evt && evt.data && evt.data.indexOf(MESSAGE.NOTIFY_IFRAME_PLACEMENT) === 0) {
    const payload = evt.data.split(':')[1]
    fillIframePlacementFromString(payload)
    if (!__iframePlacementProperlyReceived) {
      __iframePlacementProperlyReceived = true
      while (__whenIframePlacementProperlyReceived.length > 0) {
        __whenIframePlacementProperlyReceived.pop()() // calling callbacks that were waiting on proper filling
      }
    }
  }
}
if (window.parent !== window) {
  window.addEventListener('message', receiveMessage, false)
}
// ---------------- / embedding frame tells app about iframe offset an parent size ----------------

const whenIframePlacementProperlyFilled = (callback) => {
  if (__iframePlacementProperlyReceived) {
    callback()
  } else {
    __whenIframePlacementProperlyReceived.push(callback)
  }
}

export { getIframePlacement, whenIframePlacementProperlyFilled }
