import React, { useState } from 'react'
import _ from 'lodash'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import {
  createMatchPhases,
  optionsForMatch,
  isValidPick,
  clonedMatchPhasesWithChangedPick,
  phaseCompletelyPicked,
  optionsNotPickedInPhase0,
  everythingCompletelyPicked,
  nextUnpickedMatchIndexesNotInPhase0,
  clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks
} from './matchesTree'
import { allOptions } from './optionsPresenter'

const cardDragType = 'card'

const DropCard = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: cardDragType,
    drop: onDrop,
    collect: (monitor) => {
      return {
        isOver: !!monitor.isOver()
      }
    }
  })

  return (
    <span
      ref={drop}
      style={{
        display: 'inline-block',
        width: '100px',
        margin: '.25em',
        background: isOver ? '#ddd' : '#ccc',
        textAlign: 'center',
        padding: '.5em .25em'
      }}>Drop here</span>
  )
}

const DragCard = ({ option }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: option.id, type: cardDragType },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  const styleCard = { cursor: 'move', margin: '0 .25em', padding: '0 2px', background: '#ddd' }

  return (
    <span ref={drag} style={{ ...styleCard, opacity: isDragging ? '.2' : '1' }}>{option.name.substr(0, 5)}</span>
  )
}

const DragCardPlaceholder = ({ option }) => {
  const stylePlaceholder = { margin: '0 .25em', padding: '0 2px', background: '#ddd', opacity: '.2' }

  return (
    <span style={stylePlaceholder}>{option.name.substr(0, 5)}</span>
  )
}

const BracketsBet = ({ bet }) => {
  const [matchPhases, setMatchPhases] = useState(createMatchPhases())
  bet.matchPhases = matchPhases

  const makePickForMatch = (phaseIndex, matchIndex, pick) => {
    const newMatchPhases = clonedMatchPhasesWithChangedPick(bet, phaseIndex, matchIndex, pick)
    setMatchPhases(newMatchPhases)
  }

  const optionIdsLeftForPhase0 = _.map(optionsNotPickedInPhase0(bet), 'id')
  const allPicked = everythingCompletelyPicked(bet)
  const [hasNextUnpicked, nextUnpickedPhaseIndex, nextUnpickedMatchIndex] = nextUnpickedMatchIndexesNotInPhase0(bet)
  const phase0picked = phaseCompletelyPicked(bet, 0)

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='bracketsBet'>
        <div>
          <h2>Options left for Phase 0</h2>
          <div>
            {_.map(allOptions(bet), (option) => {
              const picked = !_.includes(optionIdsLeftForPhase0, option.id)
              if (picked) {
                return (
                  <DragCardPlaceholder key={option.id} option={option} />
                )
              } else {
                return (
                  <DragCard key={option.id} option={option} />
                )
              }
            })}
          </div>
        </div>
        {!phase0picked &&
          <div>
            <h2>Pick opponents for Phase 0 (or just <a onClick={() => { setMatchPhases(clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks(bet)) }}>Autocomplete</a>)</h2>
          </div>
        }
        {allPicked &&
          <div>
            <h2>All Picked! Now share!</h2>
          </div>
        }
        {!allPicked && phase0picked && hasNextUnpicked &&
          <div>
            <h2>Pick now {nextUnpickedPhaseIndex} {nextUnpickedMatchIndex}</h2>
            {(() => {
              const options = optionsForMatch(bet, nextUnpickedPhaseIndex, nextUnpickedMatchIndex)
              const style = {
                margin: '.25em',
                padding: '2px',
                display: 'inline-block',
                background: '#666',
                color: 'white',
                fontSize: '1.5em',
                width: '200px',
                textAlign: 'center'
              }
              return _.map(options, (opt) => {
                return <a key={opt.id} style={style} onClick={() => { makePickForMatch(nextUnpickedPhaseIndex, nextUnpickedMatchIndex, opt.id) }}>{opt.name}</a>
              })
            })()}
          </div>}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {_.map(matchPhases, (matchesInPhase, phaseIndex) => {
            return (
              <div key={`phase${phaseIndex}`}>
                <div>
                  <h2>Phase {phaseIndex}{phaseCompletelyPicked(bet, phaseIndex) && ' - COMPLETE'}</h2>
                  <div>
                    {_.map(matchesInPhase, (match, matchIndex) => {
                      const options = optionsForMatch(bet, phaseIndex, matchIndex)
                      const isNextUnpicked = hasNextUnpicked && phaseIndex === nextUnpickedPhaseIndex && matchIndex === nextUnpickedMatchIndex
                      return (
                        <div key={`match${matchIndex}`} style={matchIndex % 2 === 1 ? { paddingBottom: '1em' } : null}>
                          Match {matchIndex}
                          {options.length > 0 &&
                            <select style={{ opacity: isValidPick(match.pick) ? '1' : '.3' }}value={isValidPick(match.pick) ? match.pick : '0'} onChange={(evt) => { makePickForMatch(phaseIndex, matchIndex, evt.target.value) }}>
                              <option key={`default`} value={'0'}>Please pick</option>
                              {_.map(options, (option, optionIndex) => {
                                return (
                                  <option key={`${optionIndex}-${option.id}`} value={'' + option.id}>{option.name}</option>
                                )
                              })}
                            </select>
                          }
                          {!!(phaseIndex === 0) &&
                            <DropCard onDrop={({ id: pickId }) => { makePickForMatch(phaseIndex, matchIndex, pickId) }} />}
                          {phase0picked && isNextUnpicked && 'NEXT UNPICKED'}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </DndProvider>
  )
}

export default BracketsBet
