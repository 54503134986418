import React from 'react'
import Modal from 'react-modal'
import I18n from 'i18n-js'

import BetContext from '../../../context'
import BetPickCircle from '../../BetPickCircle'
import { filteredRiders, formatPlace } from '../../../utils'

import modalSaveZone from '@root/misc/modal_save_zone'

class BetPickSelectPhoneInner extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false,
      saveZoneReady: false,
      style: null
    }

    this.closeMenu = this.closeMenu.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.selectedRider = this.selectedRider.bind(this)
  }

  render () {
    const { value, riders, place, pickId } = this.props
    const saveZone = modalSaveZone.calculate()

    const pick = value.picks[pickId]
    const hasPick = !!(pick && pick !== 0)

    const style = {
      top: `${saveZone.centerY}px`,
      height: `${saveZone.height}px`,
      left: 0,
      right: 0
    }

    return (
      <React.Fragment>
        <a onClick={this.openMenu} className={`selectPlayer selectPlayer--mobile ${hasPick ? '' : 'selectPlayer--notPicked'}`} style={{ minWidth: 'initial' }}>
          <span>{hasPick ? pick.name : I18n.t('bet.select_player_mobile.btn_pick')}</span>
        </a>
        <Modal
          style={{
            content: style
          }}
          appElement={document.querySelector('#neoApp')}
          isOpen={this.state.isModalOpen}
          className='selectPlayerModal'
        >
          <div className='selectPlayerModal__header'>
            <span className='selectPlayerModal__title'>{I18n.t('bet.select_player_mobile.title', { place_formated: formatPlace(place) })}</span>
            <div onClick={this.closeMenu} className='selectPlayerModal__icon icon icon--close--rb-gray' />
          </div>
          <div className='selectPlayerModal__listWrapper'>
            <ul className='selectPlayerModal__list'>
              {filteredRiders(riders, value.picks).map((rider) => {
                return (
                  <li key={rider.name} onMouseUp={() => this.selectedRider(rider)} className='selectPlayerModal__item'>
                    <div className='selectPlayerOption'>
                      <div className='selectPlayerOption__imageContainer'>
                        <BetPickCircle
                          className='circle--full circle--smallBorder'
                          eventType={value.eventType}
                          thumb
                          pick={{ imageSrc: rider.imageSrc, thumbSrc: rider.thumbSrc, countryCode: rider.countryCode }} />
                      </div>
                      <div className='selectPlayerOption__info'>
                        <div className='selectPlayerOption__name'>
                          {rider.name}
                        </div>
                        <span className={`selectPlayerOption__country flag flag--${rider.countryCode}`}>{rider.country}</span>
                      </div>
                      <div className='selectPlayerOption__indicator'>
                        <div className='icon icon--arrow-right' />
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </Modal>
      </React.Fragment>
    )
  }

  toggleMenu () {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  closeMenu () {
    this.setState({ isModalOpen: false })
  }

  openMenu () {
    this.setState({ isModalOpen: true })
  }

  selectedRider (rider) {
    this.props.onChange(rider, { type: 'modal', name: this.props.name })
    this.closeMenu()
  }
}

const BetPickSelectPhone = React.forwardRef((props, ref) => (
  <BetContext.Consumer>
    {(value) => (
      <BetPickSelectPhoneInner ref={ref} {...props} value={value} />
    )}
  </BetContext.Consumer>
))

export default BetPickSelectPhone
