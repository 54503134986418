import React from 'react'
import _ from 'lodash'
import I18n from 'i18n-js'

const imageContext = require.context('@images', true, /.(png|jpg|svg)$/)

const SelectedDescription = ({ matchdays }) => {
  const mdIndex = _.findIndex(matchdays, { selected: true })
  if (mdIndex === -1) { return null }

  return (
    <div className='fewMatchdaysSelector__desc'>
      {I18n.t(`matchday-selector.md${mdIndex + 1}.desc`)}
    </div>
  )
}

const FewMatchdaysSelector = ({ matchdays, with_pick_description: withPickDescription }) => {
  return (
    <div className='fewMatchdaysSelector'>
      <ul className='fewMatchdaysSelector__list'>
        {_.map(matchdays, (m, i) => {
          return (
            <li key={`md${i}`} className={`fewMatchdaysSelector__listItem ${m.selected ? '--selected' : '--notSelected'}`}>
              <a href={m.url}>
                <img src={imageContext(`./matchdaySelector__md${i + 1}${m.selected ? '--selected' : ''}.png`)} />
                {m.name}
              </a>
            </li>
          )
        })}
      </ul>
      {withPickDescription &&
        <SelectedDescription matchdays={matchdays} />}
    </div>
  )
}

export default FewMatchdaysSelector
