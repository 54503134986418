import React from 'react'
import { components } from 'react-select'
import cx from 'classnames'

import BetContext from '../../../context'
import BetPickCircle from '../../BetPickCircle'

const {
  Option: ReactSelectOption,
  SingleValue: ReactSelectSingleValue,
  DropdownIndicator: ReactSelectDropdownIndicator,
  ClearIndicator: ReactSelectClearIndicator
} = components

export const ClearIndicator = (props) => (
  <ReactSelectClearIndicator {...props}>
    <div
      className={cx('select__clearIndicator')}
    />
  </ReactSelectClearIndicator>
)

export const SingleValue = (props) => (
  <ReactSelectSingleValue {...props}>
    <span className={`flag flag--${props.data.countryCode}`}>{props.children}</span>
  </ReactSelectSingleValue>
)

export const DropdownIndicator = (props) => (
  <ReactSelectDropdownIndicator {...props}>
    <div
      data-testid='dropdown'
      className={
        cx(
          'select__dropdownIndicator', {
            'select__dropdownIndicator--open': props.selectProps.menuIsOpen
          }
        )
      }
    />
  </ReactSelectDropdownIndicator>
)

export const Option = (props) => {
  return (
    <BetContext.Consumer>
      {(value) => (
        <ReactSelectOption {...props}>
          <div className='selectPlayerOption'>
            <div className='selectPlayerOption__imageContainer'>
              <BetPickCircle
                className='circle--full circle--smallBorder'
                eventType={value.eventType}
                thumb
                pick={{ imageSrc: props.data.imageSrc, thumbSrc: props.data.thumbSrc, countryCode: props.data.countryCode }} />
            </div>
            <div className='selectPlayerOption__info'>
              <div className='selectPlayerOption__name'>
                {props.data.name}
              </div>
              <span className={`selectPlayerOption__country flag flag--${props.data.countryCode}`}>{props.data.country}</span>
            </div>
            <div className='selectPlayerOption__indicator'>
              <div className='icon icon--arrow-right' />
            </div>
          </div>
        </ReactSelectOption>
      )}
    </BetContext.Consumer>
  )
}
