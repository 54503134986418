import React, { useEffect, useState } from "react"

import { toggleTheme, getTheme, themeChanged } from "@root/misc/theme"

import DebugThemeToggle from "./DebugThemeToggle"

const forceDebug = () => { return window.location.href.indexOf("//staging-") > -1 }

const ThemeToggle: React.FC<{debug?: boolean}> = ({debug}) => {
  const [ theme, setTheme ] = useState(getTheme())
  debug || (debug = forceDebug())

  useEffect(() => {
    const onThemeChanged = () => {
      setTheme(getTheme())
    }
    themeChanged.add(onThemeChanged)

    return () => { // cleanup
      themeChanged.remove(onThemeChanged)
    }
  }, [])

  return <>
    <a className={`neo__themeToggle neo__themeToggle--${theme}`} onClick={toggleTheme} />
    {debug && <DebugThemeToggle />}
  </>
}

export default ThemeToggle
