import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import I18n from 'i18n-js'
import customStyles from './styles'
import { DropdownIndicator } from './select-components'
import cx from 'classnames'
import { useTheme } from "@root/misc/theme"
import { useDevice } from "@root/misc/device"

const DropDown = ({selectedOption, options, onChange, getOptionLabel, getOptionVal}) => {
  const { theme, ref } = useTheme()
  const { isPhone } = useDevice()

  return <div ref={ref} className='selectNavl__main'>
    <Select
      value={selectedOption}
      placeholder={I18n.t('general.all_rounds_overall')}
      options={options.map((opt) => ({...opt, name: opt.name || " "}))}
      onChange={onChange}
      styles={customStyles(theme, isPhone)}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionVal}
      isSearchable={false}
      components={{ DropdownIndicator }}
    />
  </div>
}

// TODO: We could make this a default Select instead of a SelectRaceweek
class SelectRaceweek extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedOption: props.options.filter((x) => x.selected)[0]
    }

    this._handleChange = this._handleChange.bind(this)
    this._nextSelect = this._nextSelect.bind(this)
    this._prevSelect = this._prevSelect.bind(this)
  }

  render () {
    const { options, optionLabel, optionValue, noArrows } = this.props
    const currentIndex = this.getCurrentSelectIndex()

    return (
      <>
        <div className='selectNavl'>
          {!noArrows && <div
            className={cx(
              'selectNavl__action',
              'selectNavl__action--prev',
              {
                'selectNavl__action--prev--inactive': currentIndex <= 0
              }
            )}
            onClick={this._prevSelect}
          />}
          <DropDown
            onChange={this._handleChange}
            options={options}
            selectedOption={this.state.selectedOption}
            getOptionLabel={(option) => option[optionLabel]}
            getOptionVal={(option) => option[optionValue]} />
          {!noArrows && <div
            className={cx(
              'selectNavl__action',
              'selectNavl__action--next',
              {
                'selectNavl__action--next--inactive': currentIndex >= options.length - 1
              }
            )}
            onClick={this._nextSelect}
          />}
        </div>
      </>
    )
  }

  _handleChange (selectedOption) {
    this.setState({ selectedOption })
    window.location = selectedOption.url
  }

  _nextSelect () {
    const { options } = this.props

    let currentIndex = this.getCurrentSelectIndex()
    let nextIndex = currentIndex + 1

    if (nextIndex < options.length) {
      const selectedOption = options[nextIndex]

      this.setState({ selectedOption })
      this._handleChange(selectedOption)
    }
  }

  _prevSelect () {
    const { options } = this.props

    let currentIndex = this.getCurrentSelectIndex()
    let prevIndex = currentIndex - 1

    if (prevIndex >= 0) {
      const selectedOption = options[prevIndex]

      this.setState({ selectedOption })
      this._handleChange(selectedOption)
    }
  }

  getCurrentSelectIndex () {
    return this.props.options.findIndex((element) => {
      return (element.name === this.state.selectedOption.name)
    })
  }
}

SelectRaceweek.propTypes = {
  options: PropTypes.any
}
export default SelectRaceweek
