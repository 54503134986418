import React from 'react'
import { components } from 'react-select'

import SelectItem from '../SelectItem'
import Name from '../../Name'

const {
  Option: ReactSelectOption,
  SingleValue: ReactSelectSingleValue,
  DropdownIndicator: ReactSelectDropdownIndicator,
  ClearIndicator: ReactSelectClearIndicator
} = components

export const ClearIndicator = (props) => {
  return (
    <ReactSelectClearIndicator {...props}>
      <div className={'select__clearIndicator'} />
    </ReactSelectClearIndicator>)
}

export const SingleValue = (props) => {
  return (
    <ReactSelectSingleValue {...props}>
      <Name item={props.data}>{props.children}</Name>
    </ReactSelectSingleValue>)
}

export const DropdownIndicator = (props) => {
  const cls = [
    'select__dropdownIndicator',
    props.selectProps.menuIsOpen ? 'select__dropdownIndicator--open' : ''
  ].join(' ')

  return <ReactSelectDropdownIndicator {...props}>
    <div className={cls} />
  </ReactSelectDropdownIndicator>
}

export const Option = (props) => {
  return (
    <ReactSelectOption {...props}>
      <SelectItem item={props.data} />
    </ReactSelectOption>)
}
