import React, { useRef } from "react"

import Button from "./Button"

const JoinBox = ({
    info_text: infoText,
    button_text: buttonText,
    join_action: joinAction,
    authenticity_token: authenticityToken,
    invitation_token: invitationToken,
}) => {
    const formRef = useRef()
    return (
        <div className="communityJoin__card">
            <form ref={formRef} action={joinAction} method="post" acceptCharset="utf-8">
                <input type='hidden' name='authenticity_token' value={authenticityToken} />
                <input type='hidden' name='invitation_token' value={invitationToken} />
                <div className="layout__contentRow communityJoin__infoArea">
                    {infoText}
                </div>
                <div className="layout__contentRow communityJoin__infoArea">
                    <Button primary onClick={() => formRef.current?.submit()}>{buttonText}</Button>
                </div>
            </form>
        </div>
    )
}

export default JoinBox