import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import I18n from 'i18n-js'
import Select from 'react-select'

// import SelectItem from '../SelectItem'
import pickPresenter from '@root/bet_utils/presenter/pick'
import selectStyle from './style'
import { Option, SingleValue, DropdownIndicator, ClearIndicator } from './components'

const DesktopSelector = forwardRef(({ current, items, setNewPickId }, ref) => {
  const selectRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: () => {
      selectRef.current && selectRef.current.focus()
    }
  }))

  const onChange = (pick, { action }) => {
    if (action === 'select-option') {
      if (pickPresenter.isValidPick(pick) && !pickPresenter.isSame(pick, current)) {
        setNewPickId(pick.id)
      }
    }
  }

  return (
    <div className={'desktopSelector'} ref={ref}>
      <Select
        ref={selectRef}
        openMenuOnFocus
        onChange={onChange}
        value={current}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={I18n.t('SelectPlayer.placeholder', { defaults: [{ message: I18n.t('bet.select_player.placeholder') }] })}
        options={items}
        styles={selectStyle}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          ClearIndicator
        }}
      />
    </div>
  )
})

export default DesktopSelector
