import React from 'react'
import Select from 'react-select'
import I18n from 'i18n-js'

import BetContext from '../../../context'

import customStyles from './style'
import { filteredRiders } from '../../../utils'

import {
  Option, SingleValue, DropdownIndicator, ClearIndicator
} from './select-components'

class BetPickSelectDesktopInner extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value
    }

    this.selectRef = React.createRef()

    this._change = this._change.bind(this)
  }

  toggleMenu () {
    if (this.selectRef && this.selectRef.current) {
      if (this.selectRef.current.state.menuIsOpen) {
        this.selectRef.current.blur()
      } else {
        this.selectRef.current.focus()
      }
    }
  }

  render () {
    const { riders, openMenu, value, onChange, closeMenu, ...rest } = this.props

    return (
      <Select
        ref={this.selectRef}
        onMenuOpen={openMenu}
        openMenuOnFocus
        onChange={this._change}
        value={value.picks[this.props.pickId]}
        onMenuClose={closeMenu}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        placeholder={I18n.t('SelectPlayer.placeholder', { defaults: [{ message: I18n.t('bet.select_player.placeholder') }] })}
        isClearable
        options={filteredRiders(riders, value.picks)}
        styles={customStyles}
        components={{
          Option,
          SingleValue,
          DropdownIndicator,
          ClearIndicator
        }}
        {...rest}
      />
    )
  }

  _change (value, action) {
    this.setState({ value })
    this.props.onChange(value, action)
  }
}

const BetPickSelectDesktop = React.forwardRef((props, ref) => (
  <BetContext.Consumer>
    {(value) => (
      <BetPickSelectDesktopInner ref={ref} {...props} value={value} />
    )}
  </BetContext.Consumer>
))

export default BetPickSelectDesktop
