import React, { useRef, useEffect, useState } from "react"

interface Props {
  variant: string,
  onlyMobile?: boolean,
  onlyDesktop?: boolean,
  iFrameWidth: number
  src: string
}

const SponsorBoxWithScaledIframe: React.FC<Props> = ({ src, variant, onlyMobile, onlyDesktop, iFrameWidth }) => {
  const [ parentInfo, setParentInfo ] = useState({width: 0})
  const parentRef = useRef<HTMLElement>()

  const className = [
    "sponsorBox__iframeHolder",
    `sponsorBox__iframeHolder--${variant}`,
    onlyMobile ? "--mobile" : "",
    onlyDesktop ? "--desktop" : ""
  ].join(" ")

  useEffect(() => { // on resize
    const onResize = () => {
      if (parentRef.current && parentRef.current.clientHeight > 0) {
        setParentInfo({
          width: parentRef.current.clientWidth
        })
      }
    }
    window.addEventListener("resize", onResize)
    onResize()

    return () => { // cleanup
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const iFrameScale = parentInfo.width > 0
    ? parentInfo.width / iFrameWidth
    : 1

  return <div className={className} ref={(r) => { if (r) { parentRef.current = r}}}>
    <iframe src={src} style={{transform: `scale(${iFrameScale})`}} />
  </div>
}

export default SponsorBoxWithScaledIframe
