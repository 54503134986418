import React, { useState } from 'react'
import _ from 'lodash'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import BetStatus from '@root/bet_utils/BetStatus'
import { UiContext } from './context'
import BetContext from '@root/bet_utils/context/bet'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import savePicks from '@root/bet_utils/save_picks'

import {
  arrayToMatchPhases,
  matchPhasesToArray,
  optionsForMatch,
  clonedMatchPhasesWithChangedPick,
  phaseCompletelyPicked,
  optionsNotPickedInPhase0,
  everythingCompletelyPicked,
  nextUnpickedMatchIndexesNotInPhase0,
  clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks
} from './matchesTree'
import { allOptions } from './optionsPresenter'

import DragCard, { Placeholder as DragCardPlaceholder } from './DragCard'
import {
  NormalMatch,
  Phase0MatchPack
} from './Match'

const MatchTitle = ({ children }) => { return <h3>{children}</h3> }

const BracketsBet = ({ bet, authenticityToken, forceLayout, forceInteraction }) => {
  addSecondsLeftToBet(bet) // seconds left automatically calculated
  const canEdit = betStatusPresenter.canEdit(bet)

  let enableDragdrop = false
  let wideLayout = true
  if (forceInteraction === 'dragdrop') { enableDragdrop = true }
  if (forceInteraction === 'dropdown') { enableDragdrop = false }
  if (forceLayout === 'wide') { wideLayout = true }
  if (forceLayout === 'narrow') { wideLayout = false }

  const [matchPhases, setMatchPhases] = useState(arrayToMatchPhases(bet.picks || []))
  bet.matchPhases = matchPhases

  const sendNewMatchPhasesToServer = (newMatchPhases) => {
    const arr = matchPhasesToArray(newMatchPhases)
    savePicks(bet, authenticityToken, arr,
      /* OK    */ (msg) => { console.log('save OK', msg) },
      /* ERROR */ (errorMsg) => { console.log('save ERROR', errorMsg) })
  }

  const makePickForMatch = (phaseIndex, matchIndex, pick) => {
    if (!canEdit) { return }
    const newMatchPhases = clonedMatchPhasesWithChangedPick(bet, phaseIndex, matchIndex, pick)
    setMatchPhases(newMatchPhases)
    sendNewMatchPhasesToServer(newMatchPhases)
  }

  const fillPhase0Randomly = () => {
    if (!canEdit) { return }
    const newMatchPhases = clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks(bet)
    setMatchPhases(newMatchPhases)
    sendNewMatchPhasesToServer(newMatchPhases)
  }

  const optionIdsLeftForPhase0 = _.map(optionsNotPickedInPhase0(bet), 'id')
  const allPicked = everythingCompletelyPicked(bet)
  const [hasNextUnpicked, nextUnpickedPhaseIndex, nextUnpickedMatchIndex] = nextUnpickedMatchIndexesNotInPhase0(bet)
  const phase0picked = phaseCompletelyPicked(bet, 0)

  const className = [
    'bracketsBet',
    wideLayout ? 'bracketsBet--wide' : 'bracketsBet--narrow',
    enableDragdrop ? 'bracketsBet--dragdrop' : 'bracketsBet--dropdown'
  ].join(' ')

  return (
    <BetContext.Provider value={bet}>
      <UiContext.Provider value={{ canEdit, wideLayout, enableDragdrop }}>
        <DndProvider backend={HTML5Backend}>
          <div className={className}>
            <h1>
              {enableDragdrop ? 'DragDrop' : 'Dropdown'}
              + {wideLayout ? 'Wide Layout' : 'Narrow Layout'}
              + {canEdit ? 'can edit' : 'can\'t edit'}
            </h1>
            <BetStatus />
            {canEdit && enableDragdrop &&
              <div>
                <h2>Drag Players to the initial Matches</h2>
                <div>
                  {_.map(allOptions(bet), (option) => {
                    const picked = !_.includes(optionIdsLeftForPhase0, option.id)
                    if (picked) {
                      return (
                        <DragCardPlaceholder key={option.id} option={option} />
                      )
                    } else {
                      return (
                        <DragCard key={option.id} option={option} />
                      )
                    }
                  })}
                </div>
              </div>
            }
            {canEdit && !phase0picked &&
              <div>
                <h2>Pick opponents for Phase 0 (or just <a onClick={fillPhase0Randomly}>Autocomplete</a>)</h2>
              </div>
            }
            {allPicked &&
              <div>
                <h2>All Picked! Now share!</h2>
              </div>
            }
            {canEdit && !allPicked && phase0picked && hasNextUnpicked &&
              <div>
                <h2>Pick now {nextUnpickedPhaseIndex} {nextUnpickedMatchIndex}</h2>
                {(() => {
                  const options = optionsForMatch(bet, nextUnpickedPhaseIndex, nextUnpickedMatchIndex)
                  const style = {
                    margin: '.25em',
                    padding: '2px',
                    display: 'inline-block',
                    background: '#666',
                    color: 'white',
                    fontSize: '1.5em',
                    width: '200px',
                    textAlign: 'center'
                  }
                  return _.map(options, (opt) => {
                    return <a key={opt.id} style={style} onClick={() => { makePickForMatch(nextUnpickedPhaseIndex, nextUnpickedMatchIndex, opt.id) }}>{opt.name}</a>
                  })
                })()}
              </div>}
            <div className='bracketsBet__phases'>
              <div className='bracketsBet__phase bracketsBet__phase--0 bracketsBet__phase--0--a'>
                <MatchTitle>1</MatchTitle>
                <Phase0MatchPack match1Index={0} match2Index={1} setPick={makePickForMatch} />
                <MatchTitle>2</MatchTitle>
                <Phase0MatchPack match1Index={2} match2Index={3} setPick={makePickForMatch} />
                <MatchTitle>3</MatchTitle>
                <Phase0MatchPack match1Index={4} match2Index={5} setPick={makePickForMatch} />
                <MatchTitle>4</MatchTitle>
                <Phase0MatchPack match1Index={6} match2Index={7} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--0 bracketsBet__phase--0--b'>
                <MatchTitle>5</MatchTitle>
                <Phase0MatchPack match1Index={8} match2Index={9} setPick={makePickForMatch} />
                <MatchTitle>6</MatchTitle>
                <Phase0MatchPack match1Index={10} match2Index={11} setPick={makePickForMatch} />
                <MatchTitle>7</MatchTitle>
                <Phase0MatchPack match1Index={12} match2Index={13} setPick={makePickForMatch} />
                <MatchTitle>8</MatchTitle>
                <Phase0MatchPack match1Index={14} match2Index={15} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--1 bracketsBet__phase--1--a'>
                <NormalMatch phaseIndex={1} matchIndex={0} setPick={makePickForMatch} />
                <MatchTitle>9</MatchTitle>
                <NormalMatch phaseIndex={1} matchIndex={1} setPick={makePickForMatch} />

                <NormalMatch phaseIndex={1} matchIndex={2} setPick={makePickForMatch} />
                <MatchTitle>10</MatchTitle>
                <NormalMatch phaseIndex={1} matchIndex={3} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--1 bracketsBet__phase--1--b'>
                <NormalMatch phaseIndex={1} matchIndex={4} setPick={makePickForMatch} />
                <MatchTitle>11</MatchTitle>
                <NormalMatch phaseIndex={1} matchIndex={5} setPick={makePickForMatch} />

                <NormalMatch phaseIndex={1} matchIndex={6} setPick={makePickForMatch} />
                <MatchTitle>12</MatchTitle>
                <NormalMatch phaseIndex={1} matchIndex={7} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--2 bracketsBet__phase--2--a'>
                <NormalMatch phaseIndex={2} matchIndex={0} setPick={makePickForMatch} />
                <MatchTitle>13</MatchTitle>
                <NormalMatch phaseIndex={2} matchIndex={1} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--2 bracketsBet__phase--2--b'>
                <NormalMatch phaseIndex={2} matchIndex={2} setPick={makePickForMatch} />
                <MatchTitle>14</MatchTitle>
                <NormalMatch phaseIndex={2} matchIndex={3} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--3 bracketsBet__phase--3--a'>
                <NormalMatch phaseIndex={3} matchIndex={0} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--3 bracketsBet__phase--3--b'>
                <NormalMatch phaseIndex={3} matchIndex={1} setPick={makePickForMatch} />
              </div>
              <div className='bracketsBet__phase bracketsBet__phase--4'>
                <NormalMatch phaseIndex={4} matchIndex={0} setPick={makePickForMatch} finalWinner />
              </div>
            </div>
          </div>
        </DndProvider>
      </UiContext.Provider>
    </BetContext.Provider>
  )
}

export default BracketsBet
