import exportGlobally from "./exportGlobally"
import Rails from "@rails/ujs"
import _ from "lodash"

let __initialized = false

const initialize = () => {
  if (__initialized) { return }
  __initialized = true
  exportGlobally("openViaPOST", openViaPOST)
}

const openViaPOST = (url: string, params?: any) => {
  const data = _.merge({}, params)
  const csrfParam = Rails.csrfParam()
  if (csrfParam !== null) { data[csrfParam] = Rails.csrfToken() }
  const form = document.createElement("form")
  form.appendChild(document.createElement("div"))

  const inputMethod = document.createElement("input")
  inputMethod.setAttribute("name", "_method")
  inputMethod.setAttribute("type", "hidden")
  inputMethod.setAttribute("value", "POST")
  form.appendChild(inputMethod)

  _.each(data, (val, key) => {
    const input = document.createElement("input")
    input.setAttribute("name", key)
    input.setAttribute("type", "hidden")
    input.setAttribute("value", val)
    form.appendChild(input)
  })

  const inputSubmit = document.createElement("input")
  inputSubmit.setAttribute("type", "submit")
  form.appendChild(inputSubmit)

  form.method = "post"
  form.action = url
  form.style.display = "none"
  document.body.appendChild(form)
  inputSubmit.click()
}

export { initialize, openViaPOST }
