import moment from 'moment'

const postponedPresenter = {
  isPostponed: (bet) => { return !!bet.postponed },
  hasDate: (bet) => { return postponedPresenter.isPostponed(bet) && !!bet.postponed_date },
  hasUrl: (bet) => { return postponedPresenter.isPostponed(bet) && !!bet.postponed_url },
  getPrettyDate: (bet) => {
    return postponedPresenter.hasDate(bet)
      ? moment(bet.postponed_date).format('MMM Do | HH:mm')
      : ''
  },
  getUrl: (bet) => { return postponedPresenter.hasUrl ? bet.postponed_url : '' }
}

export default postponedPresenter
