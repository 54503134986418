const RTL = 'direction-rtl'
const LTR = 'direction-ltr'

window.__toggleTextDirection = () => {
  const body = document.body
  if (body.classList.contains(LTR)) {
    body.classList.remove(LTR)
    body.classList.add(RTL)
    body.style.direction = 'rtl'
  } else {
    body.classList.remove(RTL)
    body.classList.add(LTR)
    body.style.direction = 'ltr'
  }
}
