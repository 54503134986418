import React from 'react'

import CircleImg from '@root/bet_utils/CircleImg'

const wrapperStyle = {
  display: 'inline-block',
  margin: '.25em',
  overflow: 'hidden',
  padding: '.5em',
  border: '1px solid #ccc',
  width: '160px',
  height: 'auto',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center'
}

const largeImgStyle = {
  margin: '.25em auto',
  position: 'relative',
  width: '120px',
  height: '120px'
}

const thumbImgStyle = {
  margin: '.25em auto',
  position: 'relative',
  width: '60px',
  height: '60px'
}

const flagStyle = {
  margin: '.25em auto',
  display: 'inline-block',
  width: '50px',
  height: '30px'
}

const SelectOption = ({ option }) => {
  return (
    <div style={wrapperStyle}>
      <h3>{option.name}</h3>
      <div style={largeImgStyle}><CircleImg item={option} /></div>
      <div style={thumbImgStyle}><CircleImg thumb item={option} /></div>
      <div>{option.country}</div>
      <div style={flagStyle} className={`flag__asBg flag__asBg--${option.countryCode}`} />
    </div>
  )
}

export default SelectOption
