import React, { useContext } from 'react'
import I18n from 'i18n-js'

import BetContext from './context'
import { formatPlace } from './utils'

const FinalPlace = ({ place, rider }) => {
  const hasRider = !!(rider && rider !== 0)
  return (
    <div key={place} className={`bet__finalResult__place bet__finalResult__place--${place}`}>
      <div className='bet__finalResult__place__title'>{I18n.t('bet.place.full', { place_formated: formatPlace(place) })}</div>
      {hasRider &&
        <div className={`bet__finalResult__place__name flag flag--${rider.countryCode}`}>{rider.name}</div>}
      {!hasRider &&
        <div className='bet__finalResult__place__empty'>-</div>}
    </div>
  )
}

function FinalResult () {
  const betContext = useContext(BetContext)

  if (!betContext.raceOver) { return null }

  const isScored = !!(betContext.finalResults && betContext.finalResults.length > 0)

  if (isScored) {
    return (
      <div className='bet__footer bet__finalResult'>
        <h3>{I18n.t('bet.scoring.final_title')}</h3>
        <div className='bet__finalResult__standings'>
          <FinalPlace place={1} rider={betContext.finalResults[0] || 0} />
          <FinalPlace place={2} rider={betContext.finalResults[1] || 0} />
          <FinalPlace place={3} rider={betContext.finalResults[2] || 0} />
        </div>
      </div>
    )
  } else {
    return (
      <div className='bet__footer bet__finalResult__comingSoon'>
        {I18n.t('bet.scoring.waiting')}
      </div>
    )
  }
}

export default FinalResult
