import _ from 'lodash'

import { allOptions, optionForId } from './optionsPresenter'

const matchDependencies = [
  [ // phase 0
    /*  0 */ { indexInArray: 0, feedsIntoNextPhaseMatch: 0 },
    /*  1 */ { indexInArray: 1, feedsIntoNextPhaseMatch: 0 },
    /*  2 */ { indexInArray: 2, feedsIntoNextPhaseMatch: 1 },
    /*  3 */ { indexInArray: 3, feedsIntoNextPhaseMatch: 1 },
    /*  4 */ { indexInArray: 4, feedsIntoNextPhaseMatch: 2 },
    /*  5 */ { indexInArray: 5, feedsIntoNextPhaseMatch: 2 },
    /*  6 */ { indexInArray: 6, feedsIntoNextPhaseMatch: 3 },
    /*  7 */ { indexInArray: 7, feedsIntoNextPhaseMatch: 3 },
    /*  8 */ { indexInArray: 8, feedsIntoNextPhaseMatch: 4 },
    /*  0 */ { indexInArray: 9, feedsIntoNextPhaseMatch: 4 },
    /* 10 */ { indexInArray: 10, feedsIntoNextPhaseMatch: 5 },
    /* 11 */ { indexInArray: 11, feedsIntoNextPhaseMatch: 5 },
    /* 12 */ { indexInArray: 12, feedsIntoNextPhaseMatch: 6 },
    /* 13 */ { indexInArray: 13, feedsIntoNextPhaseMatch: 6 },
    /* 14 */ { indexInArray: 14, feedsIntoNextPhaseMatch: 7 },
    /* 15 */ { indexInArray: 15, feedsIntoNextPhaseMatch: 7 }
  ],
  [ // phase 1
    /*  0 */ { indexInArray: 16, fromPrevPhaseMatches: [0, 1], feedsIntoNextPhaseMatch: 0 },
    /*  1 */ { indexInArray: 17, fromPrevPhaseMatches: [2, 3], feedsIntoNextPhaseMatch: 0 },
    /*  2 */ { indexInArray: 18, fromPrevPhaseMatches: [4, 5], feedsIntoNextPhaseMatch: 1 },
    /*  3 */ { indexInArray: 19, fromPrevPhaseMatches: [6, 7], feedsIntoNextPhaseMatch: 1 },
    /*  4 */ { indexInArray: 20, fromPrevPhaseMatches: [8, 9], feedsIntoNextPhaseMatch: 2 },
    /*  5 */ { indexInArray: 21, fromPrevPhaseMatches: [10, 11], feedsIntoNextPhaseMatch: 2 },
    /*  6 */ { indexInArray: 22, fromPrevPhaseMatches: [12, 13], feedsIntoNextPhaseMatch: 3 },
    /*  7 */ { indexInArray: 23, fromPrevPhaseMatches: [14, 15], feedsIntoNextPhaseMatch: 3 }
  ],
  [ // phase 2
    /*  0 */ { indexInArray: 24, fromPrevPhaseMatches: [0, 1], feedsIntoNextPhaseMatch: 0 },
    /*  1 */ { indexInArray: 25, fromPrevPhaseMatches: [2, 3], feedsIntoNextPhaseMatch: 0 },
    /*  2 */ { indexInArray: 26, fromPrevPhaseMatches: [4, 5], feedsIntoNextPhaseMatch: 1 },
    /*  3 */ { indexInArray: 27, fromPrevPhaseMatches: [6, 7], feedsIntoNextPhaseMatch: 1 }
  ],
  [ // phase 3
    /*  0 */ { indexInArray: 28, fromPrevPhaseMatches: [0, 1], feedsIntoNextPhaseMatch: 0 },
    /*  1 */ { indexInArray: 29, fromPrevPhaseMatches: [2, 3], feedsIntoNextPhaseMatch: 0 }
  ],
  [ // phase 4
    /*  0 */ { indexInArray: 30, fromPrevPhaseMatches: [0, 1] }
  ]
]

const arrayToMatchPhases = (arr) => {
  const matchPhasesCoords = (arrayIndex) => {
    for (let phaseIndex = 0, phase; (phase = matchDependencies[phaseIndex]); phaseIndex++) {
      for (let matchIndex = 0, match; (match = phase[matchIndex]); matchIndex++) {
        if (match.indexInArray === arrayIndex) { return [ phaseIndex, matchIndex ] }
      }
    }
    throw new Error(`No mapping for arrayIndex ${arrayIndex}`)
  }

  const matchPhases = createMatchPhases()
  for (let i = 0; i < arr.length; i++) {
    const pick = arr[i]
    if (isValidPick(pick)) {
      const [ phaseIndex, matchIndex ] = matchPhasesCoords(i)
      matchPhases[phaseIndex][matchIndex].pick = pick
      matchPhases[phaseIndex][matchIndex].winner = pick
    }
  }
  return matchPhases
}

const matchPhasesToArray = (matchPhases) => {
  const arr = []
  for (let phaseIndex = 0, phase; (phase = matchPhases[phaseIndex]); phaseIndex++) {
    for (let matchIndex = 0, match; (match = phase[matchIndex]); matchIndex++) {
      const pick = match.pick
      arr[matchDependencies[phaseIndex][matchIndex].indexInArray] = isValidPick(pick) ? pick : 0
    }
  }
  return arr
}

const createMatchPhases = () => {
  return [
    [ // phase 0
      /*  0 */ { winner: 0, pick: 0 },
      /*  1 */ { winner: 0, pick: 0 },
      /*  2 */ { winner: 0, pick: 0 },
      /*  3 */ { winner: 0, pick: 0 },
      /*  4 */ { winner: 0, pick: 0 },
      /*  5 */ { winner: 0, pick: 0 },
      /*  6 */ { winner: 0, pick: 0 },
      /*  7 */ { winner: 0, pick: 0 },
      /*  8 */ { winner: 0, pick: 0 },
      /*  0 */ { winner: 0, pick: 0 },
      /* 10 */ { winner: 0, pick: 0 },
      /* 11 */ { winner: 0, pick: 0 },
      /* 12 */ { winner: 0, pick: 0 },
      /* 13 */ { winner: 0, pick: 0 },
      /* 14 */ { winner: 0, pick: 0 },
      /* 15 */ { winner: 0, pick: 0 }
    ],
    [ // phase 1
      /*  0 */ { winner: 0, pick: 0 },
      /*  1 */ { winner: 0, pick: 0 },
      /*  2 */ { winner: 0, pick: 0 },
      /*  3 */ { winner: 0, pick: 0 },
      /*  4 */ { winner: 0, pick: 0 },
      /*  5 */ { winner: 0, pick: 0 },
      /*  6 */ { winner: 0, pick: 0 },
      /*  7 */ { winner: 0, pick: 0 }
    ],
    [ // phase 2
      /*  0 */ { winner: 0, pick: 0 },
      /*  1 */ { winner: 0, pick: 0 },
      /*  2 */ { winner: 0, pick: 0 },
      /*  3 */ { winner: 0, pick: 0 }
    ],
    [ // phase 3
      /*  0 */ { winner: 0, pick: 0 },
      /*  1 */ { winner: 0, pick: 0 }
    ],
    [ // phase 4
      /*  0 */ { winner: null, pick: 0 }
    ]
  ]
}

const clonedMatchPhasesWithChangedPick = (bet, phaseIndex, matchIndex, pick) => {
  const previousMatchPhases = bet.matchPhases
  if (!isValidPick(pick)) { pick = null }
  const newMatchPhases = _.cloneDeep(previousMatchPhases)

  // in own phase: set pick and clear pick of neighbours if same
  const ownPhase = newMatchPhases[phaseIndex]
  for (let neighbourMatchIndex = 0; neighbourMatchIndex < newMatchPhases[phaseIndex].length; neighbourMatchIndex++) {
    const match = ownPhase[neighbourMatchIndex]
    if (neighbourMatchIndex === matchIndex) {
      // set own
      match.pick = pick
      match.winner = pick
    } else if (match.pick === pick) {
      // clear neighbour
      match.pick = null
      match.winner = null
    }
  }

  // in upcoming phases: if had previous pick, clear matches that used it
  const previousPick = previousMatchPhases[phaseIndex][matchIndex].pick
  if (previousPick) {
    for (let upcomingPhaseIndex = phaseIndex + 1; upcomingPhaseIndex < newMatchPhases.length; upcomingPhaseIndex++) {
      const upcomingPhase = newMatchPhases[upcomingPhaseIndex]
      for (let upcomingMatchIndex = 0; upcomingMatchIndex < upcomingPhase.length; upcomingMatchIndex++) {
        const upcomingMatch = upcomingPhase[upcomingMatchIndex]
        if (upcomingMatch.pick === previousPick || upcomingMatch.winner === previousPick) {
          upcomingMatch.pick = null
          upcomingMatch.winner = null
        }
      }
    }
  }
  return newMatchPhases
}

const phaseCompletelyPicked = (bet, phaseIndex) => {
  for (let i = 0, match; (match = bet.matchPhases[phaseIndex][i]); i++) {
    if (!isValidPick(match.pick)) { return false }
  }
  return true
}

const optionsNotPickedInPhase0 = (bet) => {
  const matchPhases = bet.matchPhases

  const optionIdsPickedInPhase0 = []
  _.each(matchPhases[0], (match, i) => {
    if (isValidPick(match.pick)) { optionIdsPickedInPhase0.push(match.pick) }
  })
  return _.filter(allOptions(bet), (option) => {
    return !_.includes(optionIdsPickedInPhase0, option.id)
  })
}

const optionsForMatch = (bet, phaseIndex, matchIndex) => {
  const matchPhases = bet.matchPhases

  if (phaseIndex === 0) {
    // INITIAL PHASE: Set opponents from pool of all options
    const optionIdsPickedInPhase0WithoutOwnPick = []
    _.each(matchPhases[0], (match, i) => {
      if (i !== matchIndex && isValidPick(match.pick)) { optionIdsPickedInPhase0WithoutOwnPick.push(match.pick) }
    })
    return _.filter(allOptions(bet), (option) => {
      return !_.includes(optionIdsPickedInPhase0WithoutOwnPick, option.id)
    })
  }

  // REGULAR MATCHES: Options are winners of previous matches
  const fromPrevPhaseMatches = matchDependencies[phaseIndex][matchIndex].fromPrevPhaseMatches
  const options = []
  _.each(fromPrevPhaseMatches, (mIndex) => {
    const winnerId = matchPhases[phaseIndex - 1][mIndex].winner
    if (winnerId) { options.push(optionForId(bet, winnerId)) }
  })
  return options
}

const everythingCompletelyPicked = (bet) => {
  const matchPhases = bet.matchPhases
  for (let i = 0, phase; (phase = matchPhases[i]); i++) {
    for (let u = 0, match; (match = phase[u]); u++) {
      if (!isValidPick(match.pick)) return false
    }
  }
  return true
}

const clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks = (bet) => {
  const matchPhases = bet.matchPhases

  let availableOptions = optionsNotPickedInPhase0(bet)
  const newMatchPhases = matchPhases.concat() // shallow clone phases
  newMatchPhases[0] = _.cloneDeep(matchPhases[0]) // shallow clone matches in phase0
  for (let i = 0, match; (match = newMatchPhases[0][i]); i++) {
    if (!isValidPick(match.pick)) {
      if (availableOptions.length === 0) {
        throw new Error('Not enough options left to fill phase 0')
      }
      const randomPick = _.sample(availableOptions)
      availableOptions = _.filter(availableOptions, (opt) => { return opt.id !== randomPick.id })

      match.pick = randomPick.id
      match.winner = randomPick.id
    }
  }

  return newMatchPhases
}

const nextUnpickedMatchIndexesNotInPhase0 = (bet) => {
  const matchPhases = bet.matchPhases
  for (let i = 1, phase; (phase = matchPhases[i]); i++) {
    for (let u = 0, match; (match = phase[u]); u++) {
      if (!isValidPick(match.pick)) return [true, i, u]
    }
  }
  return [ false ]
}

const isValidPick = (pick) => {
  return !!(pick && pick !== '0' && pick !== 0)
}

export {
  arrayToMatchPhases,
  matchPhasesToArray,
  createMatchPhases,
  optionsForMatch,
  isValidPick,
  clonedMatchPhasesWithChangedPick,
  phaseCompletelyPicked,
  optionsNotPickedInPhase0,
  everythingCompletelyPicked,
  nextUnpickedMatchIndexesNotInPhase0,
  clonedMatchPhasesWithRandomlyFilledMissingPhase0Picks
}
