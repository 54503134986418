import React, { useContext } from 'react'
import I18n from 'i18n-js'

import BetContext from './context/bet'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import postponedPresenter from '@root/bet_utils/presenter/postponed'
import cancelledPresenter from '@root/bet_utils/presenter/cancelled'
import { prettyTimeLeft } from '@root/bet_utils/use_deadline_countdown'

const BetStatus = ({ cssNamespace = 'betStatus' }) => {
  const bet = useContext(BetContext)
  if (cancelledPresenter.isCancelled(bet)) {
    return <span className={`${cssNamespace}__value --cancelled`}>{I18n.t('cancelled.status_text_for_bet_header')}</span>
  }
  if (postponedPresenter.isPostponed(bet)) {
    return <span className={`${cssNamespace}__value --postponed`}>{I18n.t('postponed.status')}</span>
  }
  if (betStatusPresenter.isMatchOpen(bet)) {
    return <span className={`${cssNamespace}__value --open`}>{prettyTimeLeft(bet.deadline)}</span>
  }
  if (betStatusPresenter.isMatchInProgress(bet)) {
    return <span className={`${cssNamespace}__value --matchInProgress`}>{I18n.t('bet.in_progress')}</span>
  }
  if (betStatusPresenter.isMatchOverAndWaitingForScores(bet)) {
    return <span className={`${cssNamespace}__value --matchOverWaitingForScores`}>{I18n.t('bet.scoring.event_finished')}</span>
  }
  if (betStatusPresenter.isMatchOverAndScored(bet)) {
    const gainedPoints = bet.total_score && (bet.total_score !== 0)
    return <span className={`${cssNamespace}__value --matchOverAndScored`}>{I18n.t('bet.total_score', { points: bet.total_score })}</span>
  }
  return null
}

export default BetStatus
