import cssVar from '@styles/_export'

const borderColor = '#737476'

// see: https://react-select.com/styles#style-object
const customStyles = {
  container: (provided) => ({
    ...provided,
    flex: '1 1',
    width: '100%'
  }),
  control: (provided, { menuIsOpen }) => {
    const borderRadius = 6

    return {
      ...provided,
      transition: 'none',
      borderRadius,
      borderBottomRightRadius: menuIsOpen ? 0 : borderRadius,
      borderBottomLeftRadius: menuIsOpen ? 0 : borderRadius,
      /*
        do not use borderBottomWidth. the component move a bit up and down if we change
        the borderWidth
      */
      borderBottomColor: menuIsOpen ? 'transparent' : borderColor,
      boxShadow: '0 2px 6px rgba(0, 0, 0, .1)',
      borderColor: borderColor,
      ':hover': {
        borderColor: borderColor,
        borderBottomColor: menuIsOpen ? 'transparent' : borderColor
      },
      cursor: 'pointer',
      flexWrap: 'nowrap',
      overflow: 'hidden'
    }
  },
  menu: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderRadius: 0,
    border: 1,
    borderColor: borderColor,
    borderStyle: 'solid',
    width: '137%',
    minWidth: 230
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    // paddingLeft: 0,
    color: '#000000',
    ':hover': {
      color: '#000000'
    }
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: '#ffffff',
    color: state.isFocused ? cssVar.rbRed : '#000000',
    cursor: 'pointer',
    padding: '0 16px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    ':hover': {
      color: cssVar.hoverColor
    },
    padding: '0 8px 0 10px'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'inherit',
    fontSize: '14px'
  }),
  // indicatorSeparator: () => ({}),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    fontSize: '14px'
  })
}

export default customStyles
