import React, { forwardRef } from 'react'
import MediaQuery from 'react-responsive'

import Mobile from './Mobile'
import Desktop from './Desktop'
import { breakpointTabletUp } from '@styles/_export'

const Selector = forwardRef((props, ref) => {
  return (
    <MediaQuery query={`(max-width: ${parseInt(breakpointTabletUp) - 0.4}px)`}>
      {(matches) => {
        if (matches) {
          return <Mobile ref={ref} {...props} />
        } else {
          return <Desktop ref={ref} {...props} />
        }
      }}
    </MediaQuery>
  )
})

export default Selector
