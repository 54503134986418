import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import axios from 'axios'
import I18n from 'i18n-js'

import BetContext from './context'
import FlashMessage from '../FlashMessage'

import BetPick from './BetPick'
import Countdown from './Countdown'
import FinalResult from './FinalResult'

import { getEventTypeFromString, eventIconClassForEventName } from './utils'

import postponedPresenter from '@root/bet_utils/presenter/postponed'
import PostponedInfo from '@root/bet_utils/PostponedInfo'

class Bet extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      response: null,
      deadlineCountdown: null,
      eventState: props.eventState,
      picks: this.props.picks
    }

    this._momentEventDate = moment(this.props.eventDate)
    this._momentDeadline = moment(this.props.deadline)

    this._flashMessageRef = React.createRef()

    if (this.props.picks.length < 3) {
      throw Error('To few picks expected 3 but got ' + this.props.picks.length)
    }

    this.handlePickChanged = this.handlePickChanged.bind(this)
  }

  componentWillUnmount () {
    clearInterval(this._countdownInterval)
  }

  render () {
    const { eventName, totalScore, eventLocation, deadline, riders, from_other: fromOther, censored, final_results: finalResults, race_over: raceOver } = this.props

    const eventType = getEventTypeFromString(eventName)
    const eventState = this.state.eventState

    const isInProgress = eventState === 'progress'
    const isOpen = eventState === 'open'
    const isOver = eventState === 'over'
    const isWaitingForScores = isInProgress && raceOver

    const includeFinalScoresArea = isOver || isWaitingForScores
    const includeFlashMessage = !fromOther && isOpen
    const eventIconClass = eventIconClassForEventName(eventName)
    const EventIcon = eventIconClass
      ? <span className={`icon ${eventIconClass}`} />
      : null

    return (
      <BetContext.Provider value={{ eventType, eventState, picks: this.state.picks, fromOther, censored, finalResults, raceOver }}>
        <div className='bet__header'>
          <h3 className='bet__title' data-testid='eventName'>{EventIcon}{eventName}</h3>
          {postponedPresenter.isPostponed(this.props) &&
            I18n.t('postponed.status')
          }
          {!postponedPresenter.isPostponed(this.props) &&
            <React.Fragment>
              {isInProgress && !isWaitingForScores &&
                <span className='bet__inProgress' data-testid='eventInProgress'>{I18n.t('bet.in_progress')}</span>}
              {isWaitingForScores &&
                <span className='bet__waitingForScores' data-testid='eventInProgress'>{I18n.t('bet.scoring.event_finished')}</span>}
              {isOpen &&
                <span className='bet__countdown' data-testid='eventCountdown'>
                  <Countdown deadline={deadline} onFinished={() => this.setState({ eventState: 'progress' })} />
                </span>}
              {isOver &&
                <span className='bet__totalScore' data-testid='eventTotalScore'>{I18n.t('bet.total_score', { points: totalScore })}</span>}
            </React.Fragment>}
        </div>
        <PostponedInfo bet={this.props} />
        <div className='bet__info'>
          <span className='bet__date' data-testid='eventDate'>{this._momentEventDate.format('MMM Do | HH:mm')}</span>
          <span className='bet__location' data-testid='eventLocation'>{eventLocation}</span>
        </div>
        <div className='bet__main'>
          <div className='bet__picks' data-testid='betPicks'>
            <BetPick
              place={1}
              riders={riders}
              pickId={0}
              onChangePick={this.handlePickChanged}
            />
            <BetPick
              place={2}
              riders={riders}
              pickId={1}
              onChangePick={this.handlePickChanged}
            />
            <BetPick
              place={3}
              riders={riders}
              pickId={2}
              onChangePick={this.handlePickChanged}
            />
          </div>
        </div>
        {!includeFinalScoresArea &&
          <div className='bet__flashMessageContainer'>
            {includeFlashMessage &&
              <FlashMessage ref={this._flashMessageRef}>
                {this.state.response && (
                  <div className={`bet__flashMessage bet__flashMessage--${this.state.response.type}`}>
                    {this.state.response.message}
                  </div>
                )}
              </FlashMessage>
            }
          </div>}
        {includeFinalScoresArea &&
          <FinalResult />}
      </BetContext.Provider>
    )
  }

  /*
    check of duplicated selected picks
    remove the pick (not the currently selected)

    @return Pick list without duplicates

    removeThePreviousPickWhenTheCurrentlySelectedPickIsEqualToThePreviousPick
  */
  getNewPicksState (pick, index) {
    let newPicks = [...this.state.picks]
    newPicks[index] = pick

    let newPicksLength = newPicks.length

    for (let c = index; c < (newPicksLength + index); c++) {
      for (let i = c + 1; i < (newPicksLength + index); i++) {
        let cm = c % newPicksLength
        let im = i % newPicksLength

        if (
          newPicks[cm] !== null &&
          newPicks[im] !== null &&
          newPicks[cm].id === newPicks[im].id) {
          newPicks[im] = null
        }
      }
    }

    return newPicks
  }

  pickDataForPostRequest (picks) {
    // TODO: Rewrite convert to number not needed anymore
    const bet = picks.map((pick) => (pick !== null && Number(pick.id)) || 0)
    const betId = Number(this.props.betId)
    const authenticityToken = this.props.authenticityToken

    return { bet, bet_id: betId, authenticity_token: authenticityToken }
  }

  handlePickChanged (pick, index) {
    const newPicks = this.getNewPicksState(pick, index)

    if (newPicks === this.state.picks) {
      return
    }

    this.setState({ picks: newPicks })

    axios.post('/bet', this.pickDataForPostRequest(newPicks)).then((response) => {
      this.showFlashMessage(response.data.message)
    }).catch(() => {
      this.showFlashMessage('Error on server side', 'error')
    })
  }

  showFlashMessage (message, type = 'info') {
    this.setState({ response: { message, type } })

    if (this._flashMessageRef && this._flashMessageRef.current) {
      this._flashMessageRef.current.show()
    }
  }
}

Bet.propTypes = {
  authenticityToken: PropTypes.string,
  betId: PropTypes.string,
  deadline: PropTypes.string,
  eventName: PropTypes.string.isRequired,
  eventDate: PropTypes.string,
  eventLocation: PropTypes.string,
  eventState: PropTypes.string,
  totalScore: PropTypes.number,
  picks: PropTypes.array,
  riders: PropTypes.array
}

export default Bet
