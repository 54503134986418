import React from 'react'

import pickPresenter from '@root/bet_utils/presenter/pick'

const Name = ({ item, className, showFlag, children }) => {
  if (!pickPresenter.isValidPick(item)) { return <NamePlaceholder text='-' /> }

  const cls = [
    className || '',
    (showFlag && item.countryCode) ? `flag flag--${item.countryCode}` : ''
  ].join(' ')

  return (
    <span className={cls}>{children || item.name}</span>
  )
}

const NamePlaceholder = ({ text }) => {
  return (
    <span>{text}</span>
  )
}

export { NamePlaceholder }

export default Name
