import React from 'react'
import Modal from 'react-modal'
import _ from 'lodash'
import I18n from 'i18n-js'

import modalSaveZone from '@root/misc/modal_save_zone'
import SelectItem from '../SelectItem'
import Icon from '@root/components/Icon'

const ModalTitle = ({ title, close }) => {
  return (
    <div className='mobileSelector__title'>
      <span className={'mobileSelector__title__text'}>{title}</span>
      <span className={'mobileSelector__title__closeIcon'} onClick={close}><Icon type='close--rb-gray' /></span>
    </div>
  )
}

const ModalList = ({ items, open, onSelect, onAbort }) => {
  const saveZone = modalSaveZone.calculate()
  const style = {
    top: `${saveZone.centerY}px`,
    maxHeight: `${saveZone.height}px`
  }

  return (
    <Modal
      isOpen={open}
      style={{ content: style }}
      appElement={document.querySelector('#neoApp')}
      onRequestClose={onAbort}
      className={'mobileSelector__popup'}
    >
      <ModalTitle title={I18n.t('bet.picker.pick_list_title')} close={onAbort} />
      <div className={'mobileSelector__content'}>
        {_.map(items, (option) => {
          return (
            <SelectItem key={option.id} item={option} select={() => { onSelect(option) }} />
          )
        })}
      </div>
    </Modal>
  )
}

export default ModalList
