import axios from 'axios'
import I18n from 'i18n-js'
import _ from "lodash"

const savePicks = (bet, authenticityToken, pickIds, onOk, onError) => {
  const data = {
    bet: pickIds,
    bet_id: bet.bet_id || bet.id,
    timestamp: (new Date()).getTime(),
    authenticity_token: authenticityToken
  }

  axios.post('/bet', data).then((response) => {
    onOk(response.data.message, response.data)
  }).catch(() => {
    onError(I18n.t('bet.response.fail'))
  })
}

export const throttledSavePicks = _.throttle(savePicks, 450, {leading: false, trailing: true})

export default savePicks
