import React, { useEffect, useState } from "react"

import { toggleTheme, getTheme, themeChanged } from "@root/misc/theme"

const DebugThemeToggle: React.FC = () => {
  const [ theme, setTheme ] = useState(getTheme())

  useEffect(() => {
    const onThemeChanged = () => {
      setTheme(getTheme())
    }
    themeChanged.add(onThemeChanged)

    return () => { // cleanup
      themeChanged.remove(onThemeChanged)
    }
  }, [])

  return <a className="neo__themeToggle__fixedDebug" onClick={toggleTheme}><span>{theme === "dark" ? "☾" : "☼"}</span></a>
}

export default DebugThemeToggle
