import React from 'react'
import I18n from 'i18n-js'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import {
  chartLine,
  chartAxisLabel
} from '@styles/_export'

class Chart extends React.Component {
  render () {
    const {
      data,
      title,
      subtitle,
      demo_mode: demoMode,
      demo_mode_text: demoModeText
    } = this.props
    return (
      <React.Fragment>
        {title && subtitle &&
          <div className='chart__title'>
            <h3 >{title}</h3>
            <div className='chart__title__sub'>{subtitle}</div>
          </div>
        }
        <div className='chart__chartContainer'>
          {!demoMode &&
            <ResponsiveContainer width='100%' height='100%'>
              <AreaChart data={data}>
                <Area type='linear' dataKey='points' stroke={chartLine} fill={chartLine} fillOpacity={1} />
                <XAxis stroke={chartAxisLabel} dataKey='round' axisLine={false} tickSize={0} tickMargin={4} tickFormatter={this.roundLabelShort} />
                <YAxis stroke={chartAxisLabel} dataKey='points' axisLine={false} width={24} tickFormatter={this.onlyShowTickIfNot0} tickSize={0} tickMargin={8} />
                <Tooltip content={this.renderTooltip} cursor={false} />
              </AreaChart>
            </ResponsiveContainer>
          }
          {demoMode &&
            <React.Fragment>
              <ResponsiveContainer width='100%' height='100%'>
                <AreaChart data={data}>
                  <XAxis stroke={chartAxisLabel} dataKey='round' tickSize={0} tickMargin={4} tick={false} />
                  <YAxis stroke={chartAxisLabel} dataKey='points' width={24} tickFormatter={this.onlyShowTickIfNot0} tickSize={0} tickMargin={8} />
                </AreaChart>
              </ResponsiveContainer>
              <div className='chart__demoText'>
                {demoModeText}
              </div>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    )
  }

  roundLabelShort (round) {
    if (round === 0) return ''
    return I18n.t('chart.round_label_short', { round: round })
  }

  onlyShowTickIfNot0 (tick) {
    if ('' + tick === '0') return ''
    return tick
  }

  renderTooltip ({ label, active, payload }) {
    if (active) {
      const val = payload[0].value
      return (
        <div className='chart__tooltip'>
          <div className='chart__tooltip__label'>{I18n.t('chart.round_label', { round: label })}</div>
          <div className='chart__tooltip__value'>{I18n.t('chart.points', { points: I18n.toNumber(val, { precision: 0 }) })}</div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Chart
