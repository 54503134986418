import React, { useState } from 'react'
import I18n from 'i18n-js'
import _ from 'lodash'

import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import pickPresenter from '@root/bet_utils/presenter/pick'
import optionsPresenter from '@root/bet_utils/presenter/options'
import { addSecondsLeftToBet } from '@root/bet_utils/use_deadline_countdown'
import { SelectionContext } from './context'
import BetContext from '@root/bet_utils/context/bet'
import PickContext from '@root/bet_utils/context/pick'
import SetNewPickIdContext from '@root/bet_utils/context/set_new_pick_id'
import BetStatus from '@root/bet_utils/BetStatus'
import savePicks from '@root/bet_utils/save_picks'
import DisplaySaveResult, { createOkResult, createErrorResult } from '@root/bet_utils/DisplaySaveResult'
import PostponedInfo from '@root/bet_utils/PostponedInfo'
import CirclePicker, { Wrapper as CirclePickerWrapper } from '@root/bet_utils/CirclePicker'
import Name from '@root/bet_utils/Name'
import { changePrediction } from '@root/tracking/signals'

const BetPlaceDescription = (placeIndex) => {
  return (
    <div className='selectionBet__placeDescription'>
      {I18n.t(`general.place.place_${placeIndex + 1}`)}
    </div>
  )
}

const EventTypeIcon = ({ bet }) => {
  if (!bet.meta_event_type) { return null }
  return (
    <span className={`selectionBet__eventTypeIcon selectionBet__eventTypeIcon--${bet.meta_event_type.toLowerCase()}`} />
  )
}

const prettyDateOrTBD = (bet) => {
  if (!bet.postponed) {
    return betStatusPresenter.prettyDate(bet)
  }else {
    return "TBD"
  }
}

const MetaInfo = ({ bet }) => {
  return (
    <div className={`selectionBet__metaInfo`}>
      <span>{prettyDateOrTBD(bet)}</span>
      {bet.meta_location &&
        <span>{bet.meta_location}</span>}
    </div>
  )
}

const rankedSelection = true // show 1st, 2nd, ... or just unordered?

const Wrapper = ({ variant, children }) => {
  variant || (variant = '')
  return (
    <div className={`selectionBet ${variant}`}>{ children }</div>
  )
}

const Header = ({ children }) => {
  return (
    <div className='selectionBet__header'>{ children }</div>
  )
}

const Footer = ({ children }) => {
  return (
    <div className='selectionBet__footer'>{ children }</div>
  )
}

const SelectionBet = ({ bet, authenticity_token: authenticityToken }) => {
  addSecondsLeftToBet(bet) // seconds left automatically calculated

  const [ picks, setPicks ] = useState(bet.picks || [])
  bet.picks = picks // so presenters work with updated picks
  const [ displaySaveResult, setDisplaySaveResult ] = useState(null)

  const changePickVal = (pickIndex, newValId) => {
    const newPicks = picks.slice(0)
    newPicks[pickIndex] = optionsPresenter.getOptionForId(bet, newValId)
    setPicks(newPicks) // force component update
    changePrediction.dispatch({})
    savePicks(bet, authenticityToken, _.map(newPicks, (pick) => { return pickPresenter.isValidPick(pick) ? pick.id : pick }),
      /* OK    */ (msg) => { setDisplaySaveResult(createOkResult(msg)) },
      /* ERROR */ (errorMsg) => { setDisplaySaveResult(createErrorResult(errorMsg)) })
  }

  return (
    <BetContext.Provider value={bet}>
      <Wrapper variant={rankedSelection ? 'selectionBet--ranked' : ''}>
        <Header>
          <span className='selectionBet__header__meta'><EventTypeIcon bet={bet} />{bet.event_name}</span>
          <BetStatus />
        </Header>
        <PostponedInfo />
        <MetaInfo bet={bet} />
        <CirclePickerWrapper className={`selectionBet__selections selectionBet__selections--amount${picks.length}`}>
          {_.map(picks || [], (pick, index) => {
            return (
              <React.Fragment key={`selection-${index}`}>
                <SelectionContext.Provider value={null}>
                  <PickContext.Provider value={pick}>
                    <SetNewPickIdContext.Provider value={(newPickId) => { changePickVal(index, newPickId) }}>
                      <CirclePicker pickerLabel={rankedSelection ? BetPlaceDescription(index) : null} itemIndex={index} />
                    </SetNewPickIdContext.Provider>
                  </PickContext.Provider>
                </SelectionContext.Provider>
              </React.Fragment>
            )
          })}
        </CirclePickerWrapper>
        <DisplaySaveResult classPrefix='scoreBet' saveResult={displaySaveResult} />
        {betStatusPresenter.isMatchOverAndWaitingForScores(bet) &&
          <Footer>
            {I18n.t('bet.scoring.waiting')}
          </Footer>}
        {betStatusPresenter.isMatchOverAndScored(bet) &&
          <Footer>
            <h3>{betStatusPresenter.isScoringFinal(bet) ? I18n.t('bet.scoring.final_title') : I18n.t('bet.scoring.current_title')}</h3>
            <div className='selectionBet__finalResultsList'>
              {_.map(bet.final_results, (res, index) => {
                return (
                  <div key={res.id} className={`selectionBet__finalResultsList__entry selectionBet__finalResultsList__entry--index${index}`}>
                    {rankedSelection && <span className='selectionBet__finalResultsList__entry__rank'>{I18n.t(`general.place.place_${index + 1}`)}</span>}
                    <Name className='selectionBet__finalResultsList__entry__name' item={res} showFlag />
                  </div>
                )
              })}
            </div>
          </Footer>}
      </Wrapper>
    </BetContext.Provider>
  )
}

export { Wrapper, Header, Footer }
export default SelectionBet
