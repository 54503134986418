import React, { useState } from 'react'
import KickoutMemberModal from './KickoutMemberModal'
import I18n from 'i18n-js'

function KickoutMemberButton ({ user_name: userName, kickout_member_path: kickoutMemberPath }) {
  const [isOpen, setModalOpen] = useState(false)

  return (
    <React.Fragment>
      <KickoutMemberModal isOpen={isOpen} onClose={() => setModalOpen(false)} userName={userName} kickoutMemberPath={kickoutMemberPath} />
      <a className='editMemberRole__kickout pointer' onClick={() => setModalOpen(true)}>{I18n.t('community.edit.kick_out')}</a>
    </React.Fragment>
  )
}

export default KickoutMemberButton
