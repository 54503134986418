import React, { useState, useEffect } from 'react'
import I18n from 'i18n-js'

import moment from 'moment'

const START_TICKING_SECONDS_WHEN_LESS_THAN_MINUTES = 6

const calcDuration = (momentNow, momentDeadline) => {
  return moment.duration(momentDeadline.diff(momentNow))
}

function Countdown ({ onFinished, deadline }) {
  const deadlineMoment = moment(deadline)
  const [durationMoment, setDurationMoment] = useState(calcDuration(moment(), deadlineMoment))
  const [countdownFinished, setCountdownFinished] = useState(durationMoment.asSeconds() <= 0)

  useEffect(() => {
    let intervalId = null
    if (countdownFinished) {
      // over
      onFinished && onFinished()
    } else {
      // update each second
      intervalId = window.setInterval(() => {
        const newDurationMoment = calcDuration(moment(), deadlineMoment)

        setDurationMoment(newDurationMoment)
        if (newDurationMoment.asSeconds() <= 0) { setCountdownFinished(true) }
      }, 1000)
    }

    return () => {
      // cleanup
      (intervalId !== null) && clearInterval(intervalId)
    }
  }, [countdownFinished])

  const prettyDuration = durationMoment.asMinutes() < (START_TICKING_SECONDS_WHEN_LESS_THAN_MINUTES)
    ? moment.utc(Math.max(0, durationMoment.asMilliseconds())).format('mm:ss', { trim: false })
    : I18n.t('bet.countdown.deadline_starts_in', { time_in_words: deadlineMoment.fromNow() })

  return (
    <React.Fragment>
      {prettyDuration}
    </React.Fragment>
  )
}

export default Countdown
