import React, { useRef, useState } from 'react'
import I18n from 'i18n-js'

import Button from "./Button"
import Form from "./CommunitySettingsForm"

function ChangeCommunitySettings ({
  form_open: formOpen,
  form_params: formParams,
}) {
  const [showForm, setShowForm] = useState(formOpen)

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  return (
    <React.Fragment>
      {showForm
        ? (
          <div className='changeCommunitySettings'>
            <div className='changeCommunitySettings__header'>
              <h2 className='changeCommunitySettings__title'>{I18n.t('community.settings.change_title')}</h2>
              <div className='changeCommunitySettings__icon icon pointer' onClick={toggleForm} />
            </div>
            <Form {...formParams} />
          </div>
        )
        : (<Button className='widget__button' primary role='button' onClick={toggleForm}>
            {I18n.t('community.settings.btn_change')}
          </Button>)
      }
    </React.Fragment>

  )
}

export default ChangeCommunitySettings
