import { useState, useEffect } from 'react'
import I18n from 'i18n-js'
import moment from 'moment'

const START_TICKING_SECONDS_WHEN_LESS_THAN_MINUTES = 6

const calcSecondsLeft = (deadline) => {
  const sec = Math.floor((new Date(deadline) - new Date()) / 1000)
  return sec < 0 ? 0 : sec
}

const useDeadlineCountdown = (deadline) => {
  const [secondsLeft, setSecondsLeft] = useState(calcSecondsLeft(deadline))
  const [countdownRunning, setCountdownRunning] = useState(secondsLeft > 0)

  useEffect(() => {
    if (!countdownRunning) { return } // only start countdown when necessary

    let intervalId = window.setInterval(() => {
      const newSecondsLeft = calcSecondsLeft(deadline)

      if (newSecondsLeft <= 0) {
        setCountdownRunning(false)
      }
      setSecondsLeft(newSecondsLeft)
    }, 1000)

    return () => {
      // cleanup
      ;(intervalId !== null) && clearInterval(intervalId)
    }
  }, [countdownRunning])

  return secondsLeft
}

const prettyTimeLeft = (deadline) => {
  const secondsLeft = calcSecondsLeft(deadline)
  const deadlineMoment = moment(deadline)
  return (secondsLeft / 60) < START_TICKING_SECONDS_WHEN_LESS_THAN_MINUTES
    ? moment.utc(Math.max(0, secondsLeft * 1000)).format('mm:ss', { trim: false })
    : I18n.t('bet.countdown.deadline_starts_in', { time_in_words: deadlineMoment.fromNow() })
}

const addSecondsLeftToBet = (bet) => {
  bet.secondsLeft = useDeadlineCountdown(bet.deadline)
}

export { prettyTimeLeft }
export { addSecondsLeftToBet }
export default useDeadlineCountdown
