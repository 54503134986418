import React from 'react'
import { useDrag } from 'react-dnd'

const styleCard = { cursor: 'move', margin: '0 .25em', padding: '0 2px', background: '#ddd' }
const stylePlaceholder = { margin: '0 .25em', padding: '0 2px', background: '#ddd', opacity: '.2' }

const cardDragType = 'card'

const DragCard = ({ option }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { id: option.id, type: cardDragType },
    collect: (monitor) => {
      return {
        isDragging: !!monitor.isDragging()
      }
    }
  })

  return (
    <span ref={drag} style={{ ...styleCard, opacity: isDragging ? '.2' : '1' }}>{option.name.substr(0, 5)}</span>
  )
}

const Placeholder = ({ option }) => {
  return (
    <span style={stylePlaceholder}>{option.name.substr(0, 5)}</span>
  )
}

export { Placeholder, cardDragType }
export default DragCard
