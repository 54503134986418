import React, { forwardRef, useState, useImperativeHandle } from 'react'
import I18n from 'i18n-js'

import ModalList from './ModalList'
import pickPresenter from '@root/bet_utils/presenter/pick'
import Name from '../../Name'

const MobileSelector = forwardRef(({ current, items, setNewPickId }, ref) => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(!open)
    }
  }))

  const hasPick = pickPresenter.isValidPick(current)

  return (
    <div className={'mobileSelector'} ref={ref}>
      <div className={`mobileSelector__currentName mobileSelector__currentName--${hasPick ? 'picked' : 'notPicked'}`} onClick={() => { setOpen(!open) }}>
        {hasPick ? <Name item={current} /> : I18n.t('bet.picker.please_pick')}
      </div>
      <ModalList
        open={open}
        items={items}
        onSelect={(item) => { setOpen(false); setNewPickId(item.id) }}
        onAbort={() => { setOpen(false); console.log('abort') }} />
    </div>
  )
})

export default MobileSelector
