import React, { useContext } from 'react'
import _ from 'lodash'

import BetContext from '@root/bet_utils/context/bet'
import PickContext from '@root/bet_utils/context/pick'
import SetNewPickIdContext from '@root/bet_utils/context/set_new_pick_id'
import betStatusPresenter from '@root/bet_utils/presenter/bet_status'
import pickPresenter from '@root/bet_utils/presenter/pick'
import optionsPresenter from '@root/bet_utils/presenter/options'

const Selection = () => {
  const pick = useContext(PickContext)
  const setNewPickId = useContext(SetNewPickIdContext)
  const bet = useContext(BetContext)

  const currentVal = pickPresenter.isValidPick(pick) ? '' + pick.id : '0'
  const currentName = pickPresenter.isValidPick(pick) ? '' + pick.name : '-'

  return (
    <div className='selectionBet__selection'>
      <span style={{ display: 'inline-block', minWidth: '100px', fontSize: '1.2em', fontWeight: 'bold' }}>{currentName}</span>
      { betStatusPresenter.canEdit(bet) &&
        <select value={currentVal} onChange={(evt) => { setNewPickId(evt.target.value) }}>
          <option key={'default'} value={'0'}>Please pick</option>
          {_.map(optionsPresenter.possibleOptionsWithoutPicks(bet), (option) => {
            return (
              <option key={option.id} value={'' + option.id}>{option.name}</option>
            )
          })}
        </select>
      }
    </div>
  )
}

export default Selection
