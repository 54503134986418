import { Signal } from 'signals'

const namesToSignals = {}

const createSignal = (name) => {
  if (namesToSignals[name]) {
    throw new Error(`Tracking-Signal for '${name}' already exists`)
  }
  const signal = new Signal()
  signal.trackingProps = (payload) => {
    return {
      'track-on-click': JSON.stringify({ type: name, ...payload })
    }
  }
  namesToSignals[name] = signal
  return signal
}

const signalFor = (name) => {
  if (!namesToSignals[name]) {
    throw new Error(`No Tracking-Signal for '${name}'`)
  }
  return namesToSignals[name]
}

export { signalFor }

export const ctaInteraction = createSignal('ctaInteraction')
export const topNavigationClick = createSignal('topNavigation')
export const changePrediction = createSignal('changePrediction')
export const interactWithSocialLink = createSignal('socialLink')
