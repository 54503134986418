import React, { useContext } from 'react'
import _ from 'lodash'

import BetContext from '@root/bet_utils/context/bet'
import { UiContext } from './context'

import { isValidPick, optionsForMatch } from './matchesTree'
import { optionForId } from './optionsPresenter'

import DropCard from './DropCard'
import DragCard from './DragCard'

const Match = ({ match, options, setPick, finalWinner }) => {
  const hasOptions = options && options.length > 0
  const isPicked = isValidPick(match.pick)

  return (
    <div>
      {hasOptions &&
        <React.Fragment>
          {finalWinner && isPicked && <Crown />}
          <select style={{ opacity: isPicked ? '1' : '.3' }} value={isPicked ? match.pick : '0'} onChange={(evt) => { setPick(evt.target.value) }}>
            <option key={`default`} value={'0'}>Please pick</option>
            {_.map(options, (option, optionIndex) => {
              return (
                <option key={`${optionIndex}-${option.id}`} value={'' + option.id}>{option.name}</option>
              )
            })}
          </select>
        </React.Fragment>}
      {!hasOptions &&
        '---'}
    </div>
  )
}

const Crown = () => { return <div style={{ textAlign: 'center', fontSize: '2em', fontWeight: 'bold' }}>♔</div> }

const ClosedMatch = ({ match, finalWinner }) => {
  const bet = useContext(BetContext)
  const isPicked = isValidPick(match.pick)

  return (
    <div>
      {isPicked &&
        <React.Fragment>
          {finalWinner && isPicked && <Crown />}
          {optionForId(bet, match.pick).name}
        </React.Fragment>}
      {!isPicked && '-'}
    </div>
  )
}

const Phase0DragDropMatch = ({ match1, setPick1, match2, setPick2 }) => {
  const bet = useContext(BetContext)
  const isPicked1 = isValidPick(match1.pick)
  const isPicked2 = isValidPick(match2.pick)
  const PleasePick = <span style={{ opacity: '.5' }}>Please pick</span>
  return (
    <div>
      <DropCard key={`match1`} onDrop={({ id: pickId }) => { setPick1(pickId) }}>
        {isPicked1 &&
          <DragCard option={optionForId(bet, match1.pick)} />}
        {!isPicked1 && PleasePick}
      </DropCard>
      <DropCard key={`match2`} onDrop={({ id: pickId }) => { setPick2(pickId) }}>
        {isPicked2 &&
          <DragCard option={optionForId(bet, match2.pick)} />}
        {!isPicked2 && PleasePick}
      </DropCard>
    </div>
  )
}

const Phase0DropDownMatch = ({ match1, options1, setPick1, match2, options2, setPick2 }) => {
  const isPicked1 = isValidPick(match1.pick)
  const isPicked2 = isValidPick(match2.pick)

  return (
    <div>
      {options1.length > 0 &&
        <select style={{ opacity: isValidPick(match1.pick) ? '1' : '.3' }}value={isPicked1 ? match1.pick : '0'} onChange={(evt) => { setPick1(evt.target.value) }}>
          <option key={`default`} value={'0'}>Please pick</option>
          {_.map(options1, (option, optionIndex) => {
            return (
              <option key={`${optionIndex}-${option.id}`} value={'' + option.id}>{option.name}</option>
            )
          })}
        </select>}
      {options2.length > 0 &&
        <select style={{ opacity: isValidPick(match2.pick) ? '1' : '.3' }}value={isPicked2 ? match2.pick : '0'} onChange={(evt) => { setPick2(evt.target.value) }}>
          <option key={`default`} value={'0'}>Please pick</option>
          {_.map(options2, (option, optionIndex) => {
            return (
              <option key={`${optionIndex}-${option.id}`} value={'' + option.id}>{option.name}</option>
            )
          })}
        </select>}
    </div>
  )
}

const Phase0ClosedMatch = ({ match1, match2 }) => {
  const bet = useContext(BetContext)
  const isPicked1 = isValidPick(match1.pick)
  const isPicked2 = isValidPick(match2.pick)

  return (
    <div>
      {isPicked1 ? optionForId(bet, match1.pick).name : '-'}
      -
      {isPicked2 ? optionForId(bet, match2.pick).name : '-'}
    </div>
  )
}

const Phase0MatchPack = ({ match1Index, match2Index, setPick }) => {
  const bet = useContext(BetContext)
  const { canEdit, enableDragdrop } = useContext(UiContext)

  const match1 = bet.matchPhases[0][match1Index]
  const match2 = bet.matchPhases[0][match2Index]
  const setPick1 = (pickId) => { setPick(0, match1Index, pickId) }
  const setPick2 = (pickId) => { setPick(0, match2Index, pickId) }

  if (canEdit) {
    if (enableDragdrop) {
      return <Phase0DragDropMatch match1={match1} match2={match2} setPick1={setPick1} setPick2={setPick2} />
    } else {
      return <Phase0DropDownMatch match1={match1} match2={match2} setPick1={setPick1} setPick2={setPick2}
        options1={optionsForMatch(bet, 0, match1Index)} options2={optionsForMatch(bet, 0, match2Index)} />
    }
  } else {
    return <Phase0ClosedMatch match1={match1} match2={match2} />
  }
}

const NormalMatch = ({ phaseIndex, matchIndex, setPick, finalWinner }) => {
  const bet = useContext(BetContext)
  const { canEdit } = useContext(UiContext)

  const match = bet.matchPhases[phaseIndex][matchIndex]
  const setPickBound = (pickId) => { setPick(phaseIndex, matchIndex, pickId) }

  if (canEdit) {
    return <Match match={match} setPick={setPickBound}
      options={optionsForMatch(bet, phaseIndex, matchIndex)} finalWinner={finalWinner} />
  } else {
    return <ClosedMatch match={match} finalWinner={finalWinner} />
  }
}

export {
  NormalMatch,
  Phase0MatchPack
}
