import _ from 'lodash'

import betStatusPresenter from '@root/bet_utils/presenter/bet_status'

const pickPresenter = {
  maxPick: () => { return 20 },
  isPicked: (pick) => { return pick > -1 },
  canIncrease: (pick) => { return pick < pickPresenter.maxPick() },
  canDecrease: (pick) => { return pick > 0 },
  increased: (pick, noTopLimit) => { return (noTopLimit || pickPresenter.canIncrease(pick)) ? pick + 1 : pick },
  decreased: (pick) => { return pickPresenter.canDecrease(pick) ? pick - 1 : pick },
  hasPickAtIndex: (bet, pickIndex) => { return _.isNumber(bet.picks[pickIndex]) && bet.picks[pickIndex] >= 0 },
  getPickAtIndex: (bet, pickIndex) => { return pickPresenter.hasPickAtIndex(bet, pickIndex) ? bet.picks[pickIndex] : -1 },
  pretty: (bet, pick) => {
    if (betStatusPresenter.isCensored(bet)) return '?'
    return pick < 0 ? '-' : ('' + pick)
  }
}

const winnerComp = (resArr) => {
  if (resArr[0] < resArr[1]) { return -1 }
  if (resArr[0] > resArr[1]) { return 1 }
  return 0
}

const scoreDiff = (resArr) => { return resArr[0] - resArr[1] }

const madePickAndHasResultAndReceivedPoints = (bet) => {
  return betStatusPresenter.receivedPoints(bet) &&
    bet.picks && bet.picks.length === 2 &&
    _.isNumber(bet.picks[0]) && _.isNumber(bet.picks[1]) &&
    bet.picks[0] >= 0 && bet.picks[1] >= 0 &&
    bet.final_results && bet.final_results.length === 2
}

const scoringPresenter = {
  isCorrect: (bet) => {
    if (madePickAndHasResultAndReceivedPoints(bet)) {
      return (bet.final_results[0] === bet.picks[0]) &&
        (bet.final_results[1] === bet.picks[1])
    } else {
      return false
    }
  },
  isCorrectWinDrawLose: (bet) => {
    if (madePickAndHasResultAndReceivedPoints(bet)) {
      return winnerComp(bet.final_results) === winnerComp(bet.picks)
    } else {
      return false
    }
  },
  isCorrectDifference: (bet) => {
    if (madePickAndHasResultAndReceivedPoints(bet)) {
      return scoreDiff(bet.final_results) === scoreDiff(bet.picks)
    } else {
      return false
    }
  }
}

export { pickPresenter, scoringPresenter }
